import React from 'react';

const ProjectSlider = () => {
  return (
    <div>
      <section>
        <div className="=container">
          <div className="row">
            <div id="carouselExampleIndicators" className="carousel slide" data-ride="carousel">
              <ol className="carousel-indicators">
                <li data-target="#carouselExampleIndicators" data-slide-to="0" className="active"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="1"></li>
                <li data-target="#carouselExampleIndicators" data-slide-to="2"></li>
              </ol>
              <div className="carousel-inner">
                <div className="carousel-item active">
                  <img style={{height: "100%"}} className="slider_image d-block w-100" src="assets/images/AIforbharath.png" alt="First slide" />
                </div>

                <div className="carousel-item">
                  <img style={{height: "100%"}} className="slider_image d-block w-100" src="assets/images/diamonds.png" alt="Second slide" />
                </div>

                <div className="carousel-item">
                  <img style={{height: "100%"}} className="slider_image d-block w-100" src="assets/images/R2D2.png" alt="Third slide" />
                </div>
              </div>
              <a className="carousel-control-prev" href="#carouselExampleIndicators" role="button" data-slide="prev">
                <span className="carousel-control-prev-icon" aria-hidden="true"></span>
                <span className="sr-only">Previous</span>
              </a>
              <a className="carousel-control-next" href="#carouselExampleIndicators" role="button" data-slide="next">
                <span className="carousel-control-next-icon" aria-hidden="true"></span>
                <span className="sr-only">Next</span>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ProjectSlider;

