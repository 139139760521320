import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import HeritageContent from "../Content/HeritageContent";

function Heritage(){
    return(
        <>
        <Preloader/>
        <Header/>
        <HeritageContent/>
        <Footer/>
        </>
    )
}
export default Heritage;