import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import AgricultureContent from "../Content/AgricultureContent";

function Agriculture(){
    return(
        <>
        <Preloader/>
        <Header/>
        <AgricultureContent/>
        <Footer/>
        </>
    )
}
export default Agriculture;