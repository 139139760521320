import React, { useRef, useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import axios from 'axios';
import { useReactToPrint } from 'react-to-print';

function BlogsContent() {
    const [users, setUsers] = useState([]);
    const [lastId, setLastId] = useState(0);
    const [tempId, setTempId] = useState(0);
    const [limit, setLimit] = useState(20);
    const [keyword, setKeyword] = useState("");
    const [query, setQuery] = useState("");
    const [hasMore, setHasMore] = useState(true);
    const [filterParam, setFilterParam] = useState("");
    const [newParam, setNewParam] = useState("");
    const [count, setCount] = useState(0);
    const [updatedCount, setUpdatedCount] = useState(0);
    const [inputValue, setInputValue] = useState('');
    const [searchKey, setSearchKey] = useState("");
    let lastsegment;


    useEffect(() => {
        const url = window.location.href;
        const array = url.split('/');
        const lastsegment = array[array.length - 1];
        console.log("queryParamsarray", lastsegment);
        getUsers(lastsegment);
    }, []);


    const getUsers = async (data) => {
        console.log('searchKeysdf', searchKey)
        const response = await axios.get(`https://acr.iitm.ac.in/api-https/sociityApi/emp/read.php?search_query=${lastsegment}`);


        const newUsers = response.data.result;
        setUsers([...newUsers]);
        setTempId(response.data.lastId);
        setHasMore(response.data.hasMore);
    };

    const fetchMore = () => {
        setLastId(tempId);
    };

    const handleClick = () => {
        // setSearchKey(inputValue);
        getUsers(inputValue);
    }

    const componentPDF = useRef();

    const generatePDF = useReactToPrint({
        content: () => componentPDF.current,
        documentTitle: "Userdata",
        onAfterPrint: () => alert("Data saved in PDF")
    });
    return (


        <React.Fragment>
            <section className="healthhead">
                <div className="container-fluid">
                    <h1 className="titleR">Projects</h1>
                </div>
            </section>

            <section className="singleService01">
                <div className="container-fluid largeContainer">

                    <div className="row">

                        <div className="col-xl-3 col-md-3 col-sm-12">
                            <aside className="widget service_widget">
                                <ul>
                                    <li className="active"><a href="#/health">Healthcare & Medical Technology</a></li>
                                    <li><a href="#/education">Education & Skill Development</a></li>
                                    <li><a href="#/sanitation">Sanitation & Waste Management</a></li>
                                    <li><a href="#/agriculture">Agriculture & Soil</a></li>
                                    <li><a href="#/water">Water</a></li>
                                    <li><a href="#/environment">Environment & Climate Change</a></li>
                                    <li><a href="#/energy">Energy & Sustainability</a></li>
                                    <li><a href="#/urban">Urban Housing & Infrastructure</a></li>
                                    <li><a href="#/heritage">Heritage</a></li>
                                    <li><a href="#/women">Women Empowerment</a></li>
                                </ul>
                            </aside>
                        </div>

                        <div className="col-xl-9 col-lg-9 col-md-9 col-sm-12">
                        <div className="container healthproj" style={{
                                marginTop: "3px", paddingTop: "3px", width: "80%"
                            }}>
                                <div className="wrapper healthprojwrap" style={{
                                    marginBottom: "3px", display: "flex", height: "35px"
                                }}>
                                    <input style={{ paddingLeft: "2px", border: "1px solid var(--theme-color)", boxShadow: "none", height: "34px", fontSize: "18px", fontFamily: "fira sans, sans-serif" }}
                                        type="text" className="form-control" placeholder="Enter project/faculty name..."
                                        value={inputValue} onChange={(e) => setInputValue(e.target.value)} onKeyDown={handleClick} />
                                    <div className="input-group">
                                        <button style={{ border: "1px solid var(--theme-color)", boxShadow: "none", background: "var(--theme-color", color: "#fff" }}
                                            className="input-group-text border" type="submit"
                                            onClick={handleClick}><i className="fa fa-search"></i>Search</button>
                                        <button style={{ border: "1px solid var(--theme-color)", boxShadow: "none", background: "#d19e42", color: "#fff" }}
                                            className="input-group-text border" onClick={(e) => setInputValue('')}>Clear</button>

                                            {/* <div class="list-view-button" ><a href="#/blogs"><i class="fa fa-bars" aria-hidden="true"></i>List view</a></div> */}
                                            <div class="grid-view-button"><a href="#/health"><i class="fa fa-th-large" aria-hidden="true"></i>Grid view</a></div>
                                    </div>                                    
                                </div>                                
                            </div>


                            <div className="container largeContainer">
                                <div className="row">
                                    <div className="col-md-12">
                                        {/* <h5 className="mt-3" style={{ textAlign: "center" }}>List of Proposal</h5> */}

                                        <div style={{ width: '100%', marginTop: "15px" }}>
                                            <table className="table table-bordered" >
                                                <thead className="bg-light">
                                                    <tr style={{ textAlign: "center" }}>
                                                        <th>Sl. No</th>
                                                        <th>Proposal Title</th>
                                                        <th>Faculty Name</th>
                                                        <th>View Details</th>

                                                    </tr>
                                                </thead>
                                                <tbody style={{ textAlign: "center" }}>
                                                    {users.map((user, index) => (
                                                        <tr key={index}>
                                                            <td>{index + 1}</td>
                                                            <td>{user.title}</td>
                                                            <td>{user.created_by}</td>
                                                            <td>
                                                                <div className="d-grid d-md-flex justify-content-center mb-3">
                                                                    <button className="btn btn-success" onClick={generatePDF}>View Details</button>
                                                                </div>
                                                            </td>
                                                        </tr>
                                                    ))}
                                                </tbody>
                                            </table>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </React.Fragment>
    )
}

export default BlogsContent;
