import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import PartnersContent from "../Content/PartnersContent";

function Partners(){
    return(
        <>
        <Preloader/>
        <Header/>
        <PartnersContent/>
        <Footer/>
        </>
    )
}
export default Partners;