import React from 'react'
import { useNavigate } from 'react-router-dom';
import './ProjectContent.css'

function ProjectContent() {
    const navigate = useNavigate();

    const navigateProjects = () => {        
        navigate('/health');
    };
    const navigateNoteworthy = () => {        
        navigate('/noteworthy');
    };

    return (
        <>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h1 className='project-tech'>Technology for Social impact</h1>
                            <p className='project-tech-p'>The pace of advance towards new technology and socially impactful innovation is invariably seen to pick up when the scientific community and industry recognise that their interests are aligned. Given that societal impact and sustainability have become the major focus areas of business today, these areas of common concern have grown in number and have become more exciting. Therefore, industrial contribution to long-term R&D has increased, and universities, such as IITM have become trusted partners in industry collaboration, as well as hubs of incubation so that technology transfer can take place at scale.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='proj-banner'>
                <div>
                    <div className="proj-content">
                        <h1>Explore CSR Projects</h1>
                        {/* <p>By using our website you can design your own website very beautiful. <br /> Lorem, ipsum dolor sit amet consectetur adipisicing elit. </p> */}
                        <button className='probtn' type='button' value='Open Projects' onClick={navigateProjects} >Open Projects</button>
                        <button className='probtn' type='button' value='Noteworthy Projects' onClick={navigateNoteworthy}>Noteworthy Projects</button>     
                    </div>                    
                </div>
            </section>
            <section className='proj-tab-section'>
                <div className='container tab-sec-container'>


                            <ul class="tabs" data-id="tabs-1">
                                <li class="tab tab--active" data-name="a">
                                    CoE
                                </li>
                                <li class="tab " data-name="b">
                                    Scholarship
                                </li>
                                <li class="tab" data-name="c">
                                    Research
                                </li>
                            </ul>

                            <div class="tabs-content" data-id="tabs-1">
                                <section class="tab-content__section" data-tab-section="a">
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <img className='tabsec' src="assets/images/CSR/1.jpg" alt='tab-section' />
                                            </div>
                                            <div className='col-lg-6 pt-30'>
                                            <p style={{marginTop: "10px"}}><b>CoE</b><br/>Whether data science and AI, advanced materials and manufacturing, steel technology, railway or battery engineering, IITM’s Centres of Excellence undertake research in high-impact areas. Involving cross-disciplinary expertise derived from collaborations among faculty members, students and industry professionals, these centres enrich the Institute’s innovation ecosystem through path-breaking and socially relevant research.</p>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="tab-content__section" data-tab-section="b">
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <img className='tabsec' src="assets/images/CSR/2.jpg" alt='tab-section' />
                                            </div>
                                            <div className='col-lg-6 pt-30'>
                                            <p  style={{marginTop: "10px"}}><b>Scholarship</b><br/>IITM for All is our mission. To align with this goal, we are committed to supporting students through the Merit Cum Means Scholarship. This scholarship aims to alleviate the financial concerns of future students, ensuring that financial constraints do not hinder their pursuit of education. By providing this support, we strive to empower students to overcome obstacles and take confident steps towards a brighter future.</p>
                                            </div>
                                        </div>
                                    </div>
                                </section>

                                <section class="tab-content__section" data-tab-section="c">
                                    <div className='container'>
                                        <div className='row'>
                                            <div className='col-lg-6'>
                                                <img className='tabsec' src="assets/images/CSR/3.jpg" alt='tab-section' />
                                            </div>
                                            <div className='col-lg-6 pt-30'>
                                                <p  style={{marginTop: "10px"}}><b>Research</b><br/>New technologies are transforming our daily lives – the way we live and work, the way we interact and socialise, and the way we make decisions. IITM is not only leading the research and development of such technologies, but is also helping implement inclusive solutions at scale, which are transparent and have the potential to effect enormous social good.</p>                                            
                                            </div>
                                        </div>
                                    </div>
                                </section>
                            </div>
                        </div>
                    
            </section>
        </>
    )
}

export default ProjectContent
