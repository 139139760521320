import React from 'react'
import './Gallery.css'

function GalleryContent() {
  return (
    <>
      <section className="healthhead">
        <div className="container-fluid">
          <h1 className="titleR">Photo Gallery</h1>
        </div>
      </section>
      <div className='largeContainer'>
        <div class="gallery-container" style={{marginBottom: "100px"}}>
        <a href="/" className='gal-redirect'>
          <div class="gallery-card">
            <div class="gallery-child">
              <h2 class="gallery-title">The upcoming event images</h2>
              <p class="image-count">Are on the way</p>
            </div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
          </div>
          </a>
          <a href="#/csr2023" className='gal-redirect'>
          <div class="gallery-card">
            <div class="gallery-child">
              <h2 class="gallery-title">CSR SUMMIT 2023</h2>
              <p class="image-count">44 Images</p>
            </div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
          </div>
          </a>
          <a href="#/csr2022" className='gal-redirect'>
          <div class="gallery-card">
            <div class="gallery-child">
              <h2 class="gallery-title">CSR SUMMIT 2022</h2>
              <p class="image-count">176 Images</p>
            </div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
            <div class="gallery-child"></div>
          </div>
          </a>
        </div>        
        </div>
    </>
  )
}

export default GalleryContent;