import { HashRouter, Routes, Route } from 'react-router-dom';
import Agriculture from './Pages/Agriculture';
import Benefits from './Pages/Benefits';
import Contact from './Pages/Contact';
import Education from './Pages/Education';
import Energy from './Pages/Energy';
import Environment from './Pages/Environment';
import FacultyProposals from './Pages/FacultyProposal';
import Healthcare from './Pages/Healthcare';
import Heritage from './Pages/Heritage';
import Home from './Pages/Home';
import Partners from './Pages/Partners';
import ResearchAreas from './Pages/ResearchAreas';
import ProjectCategories from './Pages/ProjectCategories';
import ProjectCategories2 from './Pages/ProjectCategories2';
import Sanitation from './Pages/Sanitation';
import SuccessStories from './Pages/SuccessStories';
import Team from './Pages/Team';
import Urban from './Pages/Urban';
import Water from './Pages/Water';
import Women from './Pages/Women';
import Work from './Pages/Work';
import Poster from "./Pages/Poster";
import GotoTop from './Component/GotoTop';
import Blogs from './Pages/Blogs';
import Events from './Pages/Events';
import CSRSubmit2022 from './Content/CSRSubmit2022';
import Projects from './Pages/Projects';
import Gallery from './Pages/Gallery';
import Noteworthy from './Pages/Noteworthy';
import CSRSubmit2023 from './Content/CSRSubmit2023';
// import ExploreProjects from './Component/ExploreProjects';

function App() {
  return (
    <HashRouter>
      {/* <ExploreProjects /> */}
      <GotoTop />
      <Routes>
        <Route path='/' element={<Home/>}></Route>
        <Route path='/facultyproposals' element={<FacultyProposals />}></Route>
        <Route path='/team' element={<Team />}></Route>
        <Route path='/successstories' element={<SuccessStories />}></Route>
        <Route path='/partners' element={<Partners />}></Route>
        <Route path='/researchareas' element={<ResearchAreas />}></Route>
        <Route path='/benefits' element={<Benefits />}></Route>
        <Route path='/ourwork' element={<Work />}></Route>        
        <Route path='/health' element={<Healthcare />}></Route>
        <Route path='/education' element={<Education />}></Route>
        <Route path='/sanitation' element={<Sanitation />}></Route>
        <Route path='/agriculture' element={<Agriculture />}></Route>
        <Route path='/water' element={<Water />}></Route>
        <Route path='/environment' element={<Environment />}></Route>
        <Route path='/energy' element={<Energy />}></Route>
        <Route path='/urban' element={<Urban />}></Route>
        <Route path='/heritage' element={<Heritage />}></Route>
        <Route path='/women' element={<Women />}></Route>
        <Route path='/contact' element={<Contact />}></Route>
        {/* <Route path='/login' element={<LoginProposals />}></Route> */}
        <Route path='/projectcategories' element={<ProjectCategories />}></Route>
        <Route path='/projectcategories2' element={<ProjectCategories2 />}></Route>
        {/* <Route path='/Poster' element={<Poster />}></Route>*/}
        <Route path='/Poster/:id' element={<Poster />}></Route>
        <Route path='/Blogs' element={<Blogs />}></Route>   
        <Route path='/events' element={<Events />}></Route>   
        <Route path='/csr2022' element={<CSRSubmit2022 />}></Route>   
        <Route path='/csr2023' element={<CSRSubmit2023 />}></Route> 
        <Route path='/projects' element={<Projects />}></Route>           
        <Route path='/gallery' element={<Gallery />}></Route>  
        <Route path='/noteworthy' element={<Noteworthy />}></Route>  
      </Routes>
    </HashRouter>
  );
}
export default App;