import React from 'react'
import Preloader from '../Component/Preloader'
import Header from '../Component/Header'
import Footer from '../Component/Footer'
import GalleryContent from '../Content/GalleryContent'

function Gallery() {
  return (
<>
        <Preloader/>
        <Header/>
        <GalleryContent/>
        <Footer/>
</>
  )
}

export default Gallery;
