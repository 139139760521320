import React from "react";
import Preloader from "../Component/Preloader";
import Header from "../Component/Header";
import HomeContent from "../Content/HomeContent";
import Footer from "../Component/Footer";
import SliderNew from "../Component/SliderNew";



function Home() {
    return (
        <>
            <Preloader />
            <Header />
            <SliderNew/>
            <HomeContent/>
            <Footer/>

        </>
    )
}
export default Home;