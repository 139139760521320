import React, { useEffect, useRef } from "react";

export default function App() {
  const videoEl = useRef(null);

  const attemptPlay = () => {
    videoEl &&
      videoEl.current &&
      videoEl.current.play().catch(error => {
        console.error("Error attempting to play", error);
      });
  };

  useEffect(() => {
    attemptPlay();
  }, []);

  return (
    <div className="App" style={{ alignItems: "center"}}>
      <h1 className="videotitle">CSR Impact Stories</h1>
      <div>
        <video
          className="videosec"                    
          loop
          muted
          controls
          alt="All the devices"
          src="./assets/BrainCentreandHTIC.mp4"
          ref={videoEl}
        />
      </div>
    </div>
  );
}
