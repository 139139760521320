import React from "react";
import Preloader from "../Component/Preloader";
import Header from "../Component/Header";
import AddProposals from "../Content/AddProposals";
import Footer from "../Component/Footer";
import LoginProposals from "../Content/LoginProposals";
import { useState } from "react";
// import {useEffect} from "react";


function FacultyProposals() {

    //const [authenticated, setauthenticated] = useState(false);
    //const [successLogin, setsuccessLogin] = useState(false);
    let AuthKey;
    const [data, setData] = useState(false);
    const [username, setusername] = useState();
    const [email, setemail] = useState();

    const sendData = (data) => {
      console.log("setData is ", data);
        setData(data)
    }

    const sendUsername = (username) => {
      console.log("sendUsername is ", username);
      setusername(username)
    }

    const sendEmail = (email) => {
      console.log("sendEmail is ", email);
      setemail(email)
    }

    if (data) {
      console.log("sendUsername is data", username);
      AuthKey = <AddProposals name={username} email={email}/>
    } else {
      AuthKey = <LoginProposals sendData={sendData} sendUsername={sendUsername} sendEmail={sendEmail} />
    }

    return(<> <Preloader/> <Header/> {
      AuthKey
    } < Footer/> </>)
}
export default FacultyProposals;
