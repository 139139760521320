import React from "react";
import './team.css'

export default function TeamContentNew() {
    return (
        <>

            <section className="page_banner_team" style={{ backgroundImage: "url(assets/images/bg/61.jpg)", maxWidth: "100%", width: "100%" }}>
            <div className="container-fluid">
                    <h1 className="titleT">Our Team</h1>
                </div>
            </section>

            <section className="teamPage">
                <div className="container largeContainer">
                    <div className="row">

                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/Mahesh.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/iitmdeanacr/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                        <a href="mailto:anitar@gmail.com">
                                            <li>
                                                <i class="fa-solid fa-envelope"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Prof. Mahesh Panchagnula</a></h3>
                                <p>Dean, Alumni & Corporate Relations - Chennai</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/kaviraj.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/kaviraj-nair-686b89/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                        <a href="mailto:kaviraj@ia.iitm.ac.in">
                                            <li>
                                                <i class="fa-solid fa-envelope"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Kaviraj Nair</a></h3>
                                <p>CEO, Office of Institutional Advancement - Chennai</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/Vasudha.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/vasudha-narasimhan-54893223/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                        <a href="mailto:vasudha@ia.iitm.ac.in">
                                            <li>
                                                <i class="fa-solid fa-envelope"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Vasudha Narasimhan</a></h3>
                                <p>Vice President, CSR - Chennai</p>
                            </div>
                        </div>
                        {/* <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/padma.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/padma-raghavan-606b602a/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                        <a href="mailto:Padmaraghavan@ia.iitm.ac.in">
                                            <li>
                                                <i class="fa-solid fa-envelope"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Padma Raghavan</a></h3>
                                <p>Vice President, CSR - Mumbai</p>
                            </div>
                        </div> */}

                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/vibha.png" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/vibha-jain-b37523b9/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                        <a href="mailto:vibha@ia.iitm.ac.in">
                                            <li>
                                                <i class="fa-solid fa-envelope"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">Vibha Jain</a></h3>
                                <p>Assistant Vice President, CSR - Mumbai</p>
                            </div>
                        </div>
                        <div className="col-lg-4 col-md-6">
                            <div className="team_01 text-center">
                                <div className="tm_thumb">
                                    <img src="assets/images/team/Rajesh.jpg" alt="" />
                                    <ul>
                                        <a href="https://www.linkedin.com/in/sivanesanrajesh/" target="_blank" rel="noreferrer">
                                            <li>
                                                <i class="fa-brands fa-linkedin"></i>
                                            </li>
                                        </a>
                                        <a href="mailto:rajesh@ia.iitm.ac.in">
                                            <li>
                                                <i class="fa-solid fa-envelope"></i>
                                            </li>
                                        </a>
                                    </ul>
                                </div>
                                <h3><a href="single-team.html">S Rajesh</a></h3>
                                <p>GM - Operations, Alumni & Corporate Relations - Chennai</p>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}