import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import ProjectContent2New from "../Content/ProjectContent2New";

function ProjectCategories2(){
    return(
        <>
        <Preloader/>
        <Header/>
        <ProjectContent2New/>
        <Footer/>
        </>
    )
}
export default ProjectCategories2;