import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
//import ProjectCategoriesContent from "../Content/ProjectCategoriesContent";
//import HealthcareContent from "../Content/HealthcareContent";
//import HealthcareContent_new from "../Content/HealthcareContent_new";
import HealthcareContent from "../Content/HealthcareContent";

function ProjectCategories(){
    return(
        <>
        <Preloader/>
        <Header/>
        <HealthcareContent/>
        <Footer/>
        </>
    )
}
export default ProjectCategories;