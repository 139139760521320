import React from 'react'
import './NoteworthyContent.css';

function NoteworthyContent() {
    function myFunction() {
        var dots = document.getElementById("dots");
        var moreText = document.getElementById("more");
        var btnText = document.getElementById("myBtn");

        if (dots.style.display === "none") {
            dots.style.display = "inline";
            btnText.innerHTML = "Read more";
            moreText.style.display = "none";
        } else {
            dots.style.display = "none";
            btnText.innerHTML = "Read less";
            moreText.style.display = "inline";
        }
    }

    function myFunction1() {
        var dots1 = document.getElementById("dots1");
        var moreText1 = document.getElementById("more1");
        var btnText1 = document.getElementById("myBtn1");

        if (dots1.style.display === "none") {
            dots1.style.display = "inline";
            btnText1.innerHTML = "Read more";
            moreText1.style.display = "none";
        } else {
            dots1.style.display = "none";
            btnText1.innerHTML = "Read less";
            moreText1.style.display = "inline";
        }
    }

    function myFunction2() {
        var dots2 = document.getElementById("dots2");
        var moreText2 = document.getElementById("more2");
        var btnText2 = document.getElementById("myBtn2");

        if (dots2.style.display === "none") {
            dots2.style.display = "inline";
            btnText2.innerHTML = "Read more";
            moreText2.style.display = "none";
        } else {
            dots2.style.display = "none";
            btnText2.innerHTML = "Read less";
            moreText2.style.display = "inline";
        }
    }

    function myFunction3() {
        var dots3 = document.getElementById("dots3");
        var moreText3 = document.getElementById("more3");
        var btnText3 = document.getElementById("myBtn3");

        if (dots3.style.display === "none") {
            dots3.style.display = "inline";
            btnText3.innerHTML = "Read more";
            moreText3.style.display = "none";
        } else {
            dots3.style.display = "none";
            btnText3.innerHTML = "Read less";
            moreText3.style.display = "inline";
        }
    }

    function myFunction4() {
        var dots4 = document.getElementById("dots4");
        var moreText4 = document.getElementById("more4");
        var btnText4 = document.getElementById("myBtn4");

        if (dots4.style.display === "none") {
            dots4.style.display = "inline";
            btnText4.innerHTML = "Read more";
            moreText4.style.display = "none";
        } else {
            dots4.style.display = "none";
            btnText4.innerHTML = "Read less";
            moreText4.style.display = "inline";
        }
    }

    function myFunction5() {
        var dots5 = document.getElementById("dots5");
        var moreText5 = document.getElementById("more5");
        var btnText5 = document.getElementById("myBtn5");

        if (dots5.style.display === "none") {
            dots5.style.display = "inline";
            btnText5.innerHTML = "Read more";
            moreText5.style.display = "none";
        } else {
            dots5.style.display = "none";
            btnText5.innerHTML = "Read less";
            moreText5.style.display = "inline";
        }
    }

    function myFunction6() {
        var dots6 = document.getElementById("dots6");
        var moreText6 = document.getElementById("more6");
        var btnText6 = document.getElementById("myBtn6");

        if (dots6.style.display === "none") {
            dots6.style.display = "inline";
            btnText6.innerHTML = "Read more";
            moreText6.style.display = "none";
        } else {
            dots6.style.display = "none";
            btnText6.innerHTML = "Read less";
            moreText6.style.display = "inline";
        }
    }

    function myFunction7() {
        var dots7 = document.getElementById("dots7");
        var moreText7 = document.getElementById("more7");
        var btnText7 = document.getElementById("myBtn7");

        if (dots7.style.display === "none") {
            dots7.style.display = "inline";
            btnText7.innerHTML = "Read more";
            moreText7.style.display = "none";
        } else {
            dots7.style.display = "none";
            btnText7.innerHTML = "Read less";
            moreText7.style.display = "inline";
        }
    }

    function myFunction8() {
        var dots8 = document.getElementById("dots8");
        var moreText8 = document.getElementById("more8");
        var btnText8 = document.getElementById("myBtn8");

        if (dots8.style.display === "none") {
            dots8.style.display = "inline";
            btnText8.innerHTML = "Read more";
            moreText8.style.display = "none";
        } else {
            dots8.style.display = "none";
            btnText8.innerHTML = "Read less";
            moreText8.style.display = "inline";
        }
    }

    function myFunction9() {
        var dots9 = document.getElementById("dots9");
        var moreText9 = document.getElementById("more9");
        var btnText9 = document.getElementById("myBtn9");

        if (dots9.style.display === "none") {
            dots9.style.display = "inline";
            btnText9.innerHTML = "Read more";
            moreText9.style.display = "none";
        } else {
            dots9.style.display = "none";
            btnText9.innerHTML = "Read less";
            moreText9.style.display = "inline";
        }
    }

    return (
        <>
            <section>
                <div className="container container-flex">
                    <main role="main">
                        <article className="article-featured">
                            <h2 className="article-title">Featured Projects</h2>
                            <img src="assets/images/thumbnail/ai.png" alt="simple white desk on a white wall with a plant on the far right side" className="article-image" />
                            <h5>AI for Bharat</h5>
                            <p className="article-body">Prime Minister Narendra Modi, in his address to the nation this Independence Day, appreciated the efforts of the Supreme Court for deciding to translate the judgements in vernacular languages. This AI-backed software SUVAS was developed using Anuvaad, an open-source judicial domain, document-translation platform that can translate judicial documents at scale.</p>
                            <a href="https://legal.economictimes.indiatimes.com/news/law-policy/pm-modi-praises-sc-for-delivering-judgments-in-regional-languages/102750979#:~:text=The%20Apex%20Court%20in%202019,translating%20judgments%20into%20vernacular%20languages." className="article-read-more" target='_blank' rel="noreferrer">AI for Bharat in News</a>
                        </article>
                    </main>

                    <div class="scrollbar" id="style-default">
                        <div class="force-overflow">
                            <aside className="sidebar">
                                <div className="sidebar-widget">
                                    <h2 className="widget-title">SOLINAS</h2>
                                    <img src="assets/images/thumbnail/scavage.png" alt="john doe" className="widget-image" />
                                    <p className="widget-body">Solinas Integrity’s flagship product HomoSEP was developed with the mission to end manual scavenging in India and help civic bodies, industries and the government by homogenizing the sludge of septic tanks, pump out slurry and provide storage and transportation without any harmful effects on the environment. </p>
                                    <a href="https://www.indiatoday.in/education-today/news/story/iit-madras-robot-to-clean-septic-tanks-ready-for-launch-1960480-2022-06-09" target='_blank' className="article-read-more" rel="noreferrer" style={{ paddingBottom: "5px" }}>SOLINAS in News</a>
                                </div>
                                <div className="sidebar-widget">
                                    <h2 className="widget-title">Lab Grown Diamonds</h2>
                                    <img src="assets/images/thumbnail/diamond.png" alt="john doe" className="widget-image" />
                                    <p className="widget-body">IIT Madras has been awarded a five-year research grant of ₹242 crores for research on Lab Grown Diamonds. This research will be focused on driving indigenization of the LGD manufacturing process. The union minister for Finance, Mrs Nirmala Sitharaman, announced the research grant while presenting the Union Budget 2023 on 1st February 2023.</p>
                                    <a href="https://www.thehindu.com/news/national/tamil-nadu/iit-madras-gets-242-crore-grant-for-research-in-lab-grown-diamonds/article66463919.ece" target='_blank' className="article-read-more" rel="noreferrer">Lab Grown Diamonds in News</a>
                                </div>
                                <div className="sidebar-widget">
                                    <h2 className="widget-title">R2D2 and Prof. Sujatha Srinivasan</h2>
                                    <img src="assets/images/thumbnail/device.png" alt="john doe" className="widget-image" />
                                    <p className="widget-body">
                                        R2D2 is the TTK Center for Rehabilitation Research and Device Development at IIT Madras, headed by Prof. Sujatha Srinivasan of the Department of Mechanical Engineering. The group is involved in research related to human movement
                                        <span id="dots1">...</span>
                                        <span id="more1">and the design and development of rehabilitation and assistive devices for people with movement impairments.<br /> The launch event was held at IIT Madras Research Park in the presence of Chief Guest Dr. Rajiv Bahl, Secretary to Government of India, Department of Health Research, and Director General, Indian Council of Medical Research (ICMR), Professor V Kamakoti, Director, IIT Madras, Prof. Ashok Jhunjhunwala, President, IIT Madras Research Park, and IIT Madras Incubation Cell, and Prof. Sujatha Srinivasan, Head, TTK Center for Rehabilitation Research and Device Development (R2D2), IIT Madras and among other dignitaries.
                                        </span>
                                    </p>
                                    <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction1} id="myBtn1">Read more</button><br />
                                    <a href="https://www.dtnext.in/news/city/iit-m-launches-national-center-for-assistive-health-technologies-719045" className="article-read-more" target='_blank' rel="noreferrer">R2D2 in News</a>
                                </div>
                                <div className="sidebar-widget">
                                    <h2 className="widget-title">Kotak Save Energy Mission</h2>
                                    <img src="assets/images/thumbnail/saveenergy.png" alt="john doe" className="widget-image" />
                                    <p className="widget-body">
                                        The “Kotak IITM Save Energy” mission is aimed at encouraging the adoption of various energy conservation measures in micro, small and medium enterprise sectors. The “Save Energy Hub” established in IITM
                                        <span id="dots2">...</span>
                                        <span id="more2">will monitor and implement energy assessment activities across the country, while the four satellite centres in the other IITs would identify
                                            the MSMEs that qualify for the assessment services, conduct audits, and provide recommendations for energy efficiency. <br />Union Minister for Education and Skill Development & Entrepreneurship Shri Dharmendra Pradhan releases the Strategic Plan 2021-27 of IIT Madras which proposed an ambitious growth phase for the Institute and dedicated Mphasis Centre for
                                            Quantum Sciences & Kotak-IIT (M) Save Energy Mission.
                                        </span>
                                    </p>
                                    <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction2} id="myBtn2">Read more</button><br />
                                    <a href="https://www.financialexpress.com/jobs-career/education-iit-madras-kotak-mahindra-save-energy-mission-2682347/" className="article-read-more" target='_blank' rel="noreferrer">Kotak-IITM Save Energy Mission in News</a>
                                </div>
                                <div className="sidebar-widget">
                                    <h2 className="widget-title">Walmart Global Tech – IITM Partnership</h2>
                                    <img src="assets/images/thumbnail/walmart.png" alt="john doe" className="widget-image" />
                                    <p className="widget-body">
                                        As part of this partnership, IITM students and associates of Walmart Global Tech (WGT) will work together on research projects facilitated by the Centre for Industrial Consultancy and Sponsored
                                        <span id="dots3">...</span>
                                        <span id="more3">Research at IITM. WGT and IITM will work on project areas such as sustainability, AI and energy engineering. <br />The agreement was signed at an event in Chennai in the presence of IITM alumnus Suresh Kumar, global chief technology officer, and chief development officer, Walmart Inc., and Professor Mahesh Panchagnula, Dean - alumni and corporate relations, IITM and Judith Ravin, the U.S. consul general in Chennai.
                                        </span>
                                    </p>
                                    <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction3} id="myBtn3">Read more</button><br />
                                    <a href="https://www.thehindu.com/news/cities/chennai/iit-madras-and-walmart-global-tech-to-work-on-research-skilling-of-students/article65210760.ece" className="article-read-more" target='_blank' rel="noreferrer">WGT-IITM in News</a>
                                </div>
                            </aside>
                        </div>
                    </div>
                </div>
            </section>
            <section>
                <aside className="container-fluid largeContainer" style={{ borderBottom: "1px solid gray", width: "80vw" }}>
                    <div className="sidebar-widget">
                        <h2 className="widget-title1">Healthcare</h2>
                        <div className='container procontainer'>
                            <div className='row'>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Machine based tumour detection</h2>
                                        <img src="assets/images/thumbnail/blackfungus.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">Researchers at IIT Madras have developed a machine learning-based computational tool for better detection of cancer-causing tumours in the brain and spinal cord. Named ‘GBMDriver’ (GlioBlastoma Mutiforme Drivers), this tool is publicly available online.</p>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.thehindu.com/news/national/tamil-nadu/iit-m-researchers-develop-tool-to-detect-glioblastoma-a-malignant-tumour-of-the-brain-spinal-cord/article66800144.ece" className="article-read-more" target='_blank' rel="noreferrer">  The Hindu,  </a>
                                            <a href="https://www.thehindubusinessline.com/news/science/iit-madras-researchers-develop-machine-learning-tool-to-detect-tumour-in-brain-spinal-cord/article66800135.ece" className="article-read-more" target='_blank' rel="noreferrer">Business Line,  </a>
                                            <a href="https://www.deccanherald.com/india/iit-madras-researchers-develop-machine-learning-tool-to-detect-tumours-in-brain-spinal-cord-1214780.html" className="article-read-more" target='_blank' rel="noreferrer">Deccan Herald</a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">TB Diagnosis Research</h2>
                                        <img src="assets/images/thumbnail/tuberclosis.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">IIT Madras partners with General Insurance Corporation of India to develop Urine-based TB diagnosis tech. The know-how and the relevant technologies will be made available to start-ups or diagnostic kit manufacturers.</p>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.thehindubusinessline.com/news/science/iit-madras-partners-with-general-insurance-corporation-of-india-to-develop-urine-based-tb-diagnosis-tech/article66540345.ece" className="article-read-more" target='_blank' rel="noreferrer">   Business Line,  </a>
                                            <a href="https://www.deccanherald.com/india/iit-m-partners-with-gic-re-to-develop-urine-based-tb-diagnosis-1194029.html#:~:text=The%20prestigious%20Indian%20Institute%20of,the%20existing%20TB%20diagnostic%20tests." className="article-read-more" target='_blank' rel="noreferrer">Deccan Herald</a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Blood Vessel Health Detector</h2>
                                        <img src="assets/images/thumbnail/pureblood.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">Called ARTSENS, this device developed by researchers at IITM, can be used in routine medical examination by even non-experts. This device can assess and predict vascular health. It is powered by a proprietary non-imaging probe and an intelligent computing platform.</p>
                                        <div className='news-links'>News Links:
                                            <a href="https://indianexpress.com/article/health-wellness/iit-madras-tablet-sized-device-measure-stiffness-heart-muscles-symptoms-heart-disease-8570909/" className="article-read-more" target='_blank' rel="noreferrer">  Indian Express,  </a>
                                            <a href="https://www.thehindubusinessline.com/news/science/iit-madras-researchers-develop-an-easy-to-use-screening-device-for-reliable-assessment-of-blood-vessel-health/article66747419.ece" className="article-read-more" target='_blank' rel="noreferrer">  Business Line,</a>
                                            <a href="https://menafn.com/1106025288/IIT-Madras-Develops-Screening-Device-For-Assessing-Blood-Vessel-Health" className="article-read-more" target='_blank' rel="noreferrer">   MenaFn</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Milk Adulteration Detection</h2>
                                        <img src="assets/images/thumbnail/uppglab.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">Researchers at IIT Madras have developed a three-dimensional (3D) paper-based portable device that can detect adulteration in milk within 30 seconds. The test could be even performed at home. Unlike conventional laboratory-based methods to test the purity of milk, which are both expensive and time-consuming, this new technology is affordable<span id="dots">...</span><span id="more">  and could also be used to test other liquids such as water, fresh juice, and milkshakes for traces of adulteration. Only a millilitre is needed for the test.</span></p>
                                        <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction} id="myBtn">Read more</button>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.newindianexpress.com/states/tamil-nadu/2023/mar/28/iit-madras-develops-portable-paper-device-to-detect-adulteration-in-milk-2560162.html" className="article-read-more" target='_blank' rel="noreferrer">  New Indian Express,  </a>
                                            <a href="https://m.timesofindia.com/city/chennai/iit-madras-develops-pocket-friendly-device-to-detect-milk-adulteration/articleshow/99047717.cms" className="article-read-more" target='_blank' rel="noreferrer">Times of India</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Brain Centre</h2>
                                        <img src="assets/images/thumbnail/ruraledu.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">The Sudha Gopalakrishnan Brain Centre aims to map the human brain at the cellular and connectivity levels. This state-of-the-art Centre will work towards becoming a world-renowned research centre, generating unprecedented human brain data, scientific output, and technology tools. Mr K VijayRaghavan, Principal Scientific Adviser to the Centre, who inaugurated the centre.</p>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.moneycontrol.com/news/trends/sudha-gopalakrishnan-brain-centre-set-up-at-iit-madras-to-map-human-brain-at-cellular-level-8257121.html" className="article-read-more" target='_blank' rel="noreferrer">  MoneyControl,  </a>
                                            <a href="https://www.forbesindia.com/article/take-one-big-story-of-the-day/why-kris-gopalakrishnan-is-spending-hundreds-of-crores-to-study-the-human-brain/85249/1" className="article-read-more" target='_blank' rel="noreferrer">   Forbes India,</a>
                                            <a href="https://www.businesstoday.in/latest/trends/story/iit-madras-inaugurates-centre-to-map-human-brains-at-cellular-levels-326555-2022-03-19" className="article-read-more" target='_blank' rel="noreferrer">   Business Today</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </section>

            <section>
                <aside className="container-fluid largeContainer" style={{ borderBottom: "1px solid gray", width: "80vw" }}>
                    <div className="sidebar-widget">
                        <h2 className="widget-title1">Education</h2>
                        <div className='container procontainer'>
                            <div className='row'>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">VR-based Education Model for Rural Schools</h2>
                                        <img src="assets/images/thumbnail/ruraleduca.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">IIT Madras Pravartak Technologies Partners with Vidya Shakti to take Education to 100 Villages in Varanasi through ‘Online Classes and Virtual Reality’.</p>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.thehindu.com/news/national/tamil-nadu/iit-madras-researchers-to-develop-vr-based-education-model-for-rural-schools/article66729012.ece" className="article-read-more" target='_blank' rel="noreferrer">  The Hindu,  </a>
                                            <a href="https://indiaeducationdiary.in/iit-madras-pravartak-technologies-partners-with-vidya-shakti-to-take-education-to-100-villages-in-varanasi-through-online-classes-and-virtual-reality/" className="article-read-more" target='_blank' rel="noreferrer">   India Education Today  </a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Thiranari Thervu Thittam - Anaivarukkum IITM</h2>
                                        <img src="assets/images/thumbnail/merit.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">
                                            Chief Minister M.K. Stalin has launched a new scheme to encourage government school students for studying in premier institutions. “Under the Tamil Nadu Chief Minister’s Thiranari Thervu Thittam,
                                            <span id="dots4">...</span>
                                            <span id="more4"> 1,000 students would be chosen from government schools to receive guidance in premier institutions such as Indian Institute of Technology, Madras.” the Chief Minister announced.<br />Such students would receive a monthly stipend of ₹1,000 to ensure that they remain motivated, he said, adding that the candidates chosen under the scheme would receive ₹12,000 as annual stipend during their undergraduate and postgraduate courses.
                                            </span>
                                        </p>
                                        <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction4} id="myBtn4">Read more</button>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.thehindu.com/news/national/tamil-nadu/cm-launches-new-scheme-for-government-school-students/article66703737.ece" className="article-read-more" target='_blank' rel="noreferrer">   The Hindu, </a>
                                            <a href="https://www.dtnext.in/tamilnadu/2023/04/05/stalin-announces-thiranari-thervu-thittam-for-students-with-1k-stipend" className="article-read-more" target='_blank' rel="noreferrer">  DT Next</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </section>

            <section>
                <aside className="container-fluid largeContainer" style={{ borderBottom: "1px solid gray", width: "80vw" }}>
                    <div className="sidebar-widget">
                        <h2 className="widget-title1">Environment Technology</h2>
                        <div className='container procontainer'>
                            <div className='row'>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">NTCPWC</h2>
                                        <img src="assets/images/thumbnail/waterways.jpg" alt="john doe" className="widget-image" />
                                        <p className="widget-body">
                                            Union Minister Shri Sarbananda Sonowal inaugurated National Technology Centre for Ports, Waterways & Coasts (NTCPWC) at Discovery Campus of IIT, Madras. NTCPWC was established under the ambitious
                                            <span id="dots5">...</span>
                                            <span id="more5"> Sagarmala program at a cost of ₹ 77 crore. The Role Model centre will provide solutions to challenges in the maritime sector through scientific support, education, applied research and technology transfer at the local, regional, national and international levels.
                                            </span>
                                        </p>
                                        <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction5} id="myBtn5">Read more</button>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.thehindubusinessline.com/economy/logistics/national-technology-centre-for-ports-waterways-coasts-to-be-inaugurated-on-monday/article66770581.ece" className="article-read-more" target='_blank' rel="noreferrer">   Business Line,  </a>
                                            <a href="https://indiaeducationdiary.in/pm-modi-welcomes-inauguration-of-national-technology-centre-for-ports-waterways-coasts-at-iit-madras-2/" className="article-read-more" target='_blank' rel="noreferrer">   India Education Diary  </a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">IoT-Based Mobile Air Pollution Monitoring</h2>
                                        <img src="assets/images/thumbnail/IoT.jpg" alt="john doe" className="widget-image" />
                                        <p className="widget-body">Researchers at IITM have developed a low-cost mobile air pollution monitoring framework in which pollution sensors mounted on public vehicles can dynamically monitor the air quality of an extended area at high spatial and temporal resolution.</p>
                                        <div className='news-links'>News Links:
                                            <a href="https://www.thehindu.com/sci-tech/energy-and-environment/iit-madras-designs-iot-based-mobile-device-to-monitor-air-quality/article66973207.ece" className="article-read-more" target='_blank' rel="noreferrer">   The Hindu, </a>
                                            <a href="https://www.fortuneindia.com/enterprise/iit-madras-develops-low-cost-air-pollution-monitoring-technology/113057" className="article-read-more" target='_blank' rel="noreferrer">  Fortune India</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Eni award for clean water innovations</h2>
                                        <img src="assets/images/thumbnail/cleanwater.jpeg" alt="john doe" className="widget-image" />
                                        <p className="widget-body">
                                            Professor T Pradeep from IIT Madras has been conferred with the renowned Eni Award for his groundbreaking work in providing affordable and sustainable clean water using advanced nanoscale
                                            <span id="dots6">...</span>
                                            <span id="more6">  materials. Indian scientist Professor Thalappil Pradeep from IIT Madras has been honored with the esteemed Eni Award for his groundbreaking research in energy and environment. The award recognises his innovative work in providing affordable clean water using advanced nanoscale materials. The President of Italy is expected to present the award to Professor Pradeep in the near future.
                                            </span>
                                        </p>
                                        <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction6} id="myBtn6">Read more</button><br />
                                        <div className='news-links'>News Links:
                                            <a href="https://www.thehindu.com/news/national/tamil-nadu/iit-madras-professor-honoured-with-international-eni-award/article67090424.ece" className="article-read-more" target='_blank' rel="noreferrer">   The Hindu – award announcement, </a>
                                            <a href="https://groundreport.in/t-pradeeps-iit-m-professor-amrit-filters-saving-lives-from-arsenic-contamination/" className="article-read-more" target='_blank' rel="noreferrer">  Amrit Filters for removal of arsenic contamination, </a>
                                            <a href="https://www.thebetterindia.com/308270/prof-thalappil-pradeep-iit-madras-technology-water-purifier-amrit-removes-arsenic-uranium/" className="article-read-more" target='_blank' rel="noreferrer">  The Better India - announcement</a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </section>

            <section>
                <aside className="container-fluid largeContainer" style={{ borderBottom: "1px solid gray", width: "80vw" }}>
                    <div className="sidebar-widget">
                        <h2 className="widget-title1">Infrastructure Technology Research</h2>
                        <div className='container procontainer'>
                            <div className='row'>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">V&PS, CDISHA</h2>
                                        <img src="assets/images/thumbnail/secure.jpg" alt="john doe" className="widget-image" />
                                        <p className="widget-body">
                                            An advanced research facility at the Indian Institute of Technology Madras (IIT-M) was inaugurated by Rajeev Chandrasekhar, Minister of State for Electronics and
                                            <span id="dots7">...</span>
                                            <span id="more7">   Information Technology, Skill Development and Entrepreneurship on April 27. The Veena and Pratap Subrahmanyam Center for Digital Intelligence, Security Hardware and Architecture (V&PS – CDISHA) will undertake various cutting-edge research using SHAKTI, the IIT Madras-developed indigenous microprocessor, including building a new class of computers to handle emerging AI applications.
                                            </span>
                                        </p>
                                        <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction7} id="myBtn7">Read more</button><br />
                                        <div className='news-links'>News Links:
                                            <a href="https://www.eetindia.co.in/iit-madras-inaugurates-state-of-the-art-research-lab/" className="article-read-more" target='_blank' rel="noreferrer">  EE Times,  </a>
                                            <a href="https://m.timesofindia.com/city/chennai/digital-economy-gives-9-to-indias-gdp-min/articleshow/99830515.cms" className="article-read-more" target='_blank' rel="noreferrer">    Times of India </a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Centre for Responsible AI</h2>
                                        <img src="assets/images/thumbnail/cerai.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">
                                            Indian Institute of Technology Madras (IIT Madras) has established a Centre for Responsible AI (CeRAI), an interdisciplinary research centre, to ensure ethical
                                            <span id="dots8">...</span>
                                            <span id="more8"> and responsible development of AI-based solutions in the real world. It is geared towards becoming a premier research centre at the National and International level for both fundamental and applied research in Responsible AI with immediate impact in deploying AI systems in the Indian ecosystem. Google is the first platinum consortium member and has contributed a sum of US$ 1 Million for this Centre.
                                            </span>
                                        </p>
                                        <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction8} id="myBtn8">Read more</button><br />
                                        <div className='news-links'>News Links:
                                            <a href="https://timesofindia.indiatimes.com/gadgets-news/iit-madras-opens-centre-for-responsible-ai-google-joins-onboard-as-member/articleshow/100263843.cms" className="article-read-more" target='_blank' rel="noreferrer">   Times of India, </a>
                                            <a href="https://www.business-standard.com/india-news/iit-madras-establishes-cerai-to-promote-ethical-responsible-use-of-ai-123051500682_1.html" className="article-read-more" target='_blank' rel="noreferrer">  Business Standard</a>
                                        </div>
                                    </div>
                                </div>

                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Xyma Analytics</h2>
                                        <img src="assets/images/thumbnail/xyma.png" alt="john doe" className="widget-image" />
                                        <p className="widget-body">
                                            XYMA Analytics, a Deep-tech Start-up that was incubated at the IITM Incubation Cell was conferred with the prestigious “National Technology Awards 2023”
                                            <span id="dots9">...</span>
                                            <span id="more9">   under the Startup Category. Known for their ground-breaking and patented waveguide ultrasonic sensor technology, Xyma Analytics was felicitated for enabling multi-point and multi-parameter sensing in their innovation, that also withstands extreme environments. Supported by many industry and innovative leaders, the deep-tech startup is also the recipient of several accolades including getting featured among Startup Tamil Nadu Top 19-Most Promising Startups, Qualcomm Design Indian Challenge 21 Finalists and the TANSEED award from the Honorable Chief Minister of Tamil Nadu.
                                            </span>
                                        </p>
                                        <button style={{ border: "none", fontSize: "17px", background: "transparent", color: "blue" }} onClick={myFunction9} id="myBtn9">Read more</button><br />
                                        <div className='news-links'>News Links:
                                            <a href="http://businessnewsthisweek.com/business/xyma-analytics-an-iitm-incubation-cell-startup-wins-the-national-technology-awards-2023/" className="article-read-more" target='_blank' rel="noreferrer">   Business This Week, </a>
                                            <a href="https://m.timesofindia.com/city/chennai/they-sensed-an-opportunity-and-took-it/articleshow/98083290.cms" className="article-read-more" target='_blank' rel="noreferrer">  Times of India</a>
                                        </div>
                                    </div>
                                </div>
                                <div className='col-lg-4 pb-5'>
                                    <div className="sidebar-widget">
                                        <h2 className="widget-title">Demand-responsive signal control to traffic jams</h2>
                                        <img src="assets/images/thumbnail/traffic.jpg" alt="john doe" className="widget-image" />
                                        <p className="widget-body">IIT Madras researchers develop demand-responsive signal control to address traffic jams. This is the first time a probe-based, demand-responsive signal control has been developed for Indian conditions.</p>
                                        <div className='news-links'>News Links:
                                            <a href="https://theprint.in/india/iit-madras-researchers-develop-demand-responsive-signal-control-to-address-traffic-jams/1590263/" className="article-read-more" target='_blank' rel="noreferrer">   The Print,  </a>
                                            <a href="https://www.mid-day.com/news/india-news/article/iit-madras-researchers-develop-demand-responsive-signal-control-to-address-traffic-jams-23288243" className="article-read-more" target='_blank' rel="noreferrer">    Mid-day  </a>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </aside>
            </section>
        </>
    )
}

export default NoteworthyContent;
