import axios from 'axios';
import React, { useState, useEffect } from 'react';
import Form from 'react-bootstrap/Form';
import { MDBValidation, MDBValidationItem, MDBBtn, MDBContainer, MDBCard, MDBCardBody, MDBCol, MDBRow, MDBInput, MDBTextArea, MDBRadio, MDBFile } from 'mdb-react-ui-kit';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
function AddProposals({name,email}) {

    const [formSubmitionStatus, setFormSubmitionStatus] = useState('notSubmitted');
    const [validated, setValidated] = useState(false);

    const [formValues, setformValues] = useState();
    const [inputValues, setInputValue] = useState({
        title: "",
        description: "",
    });
    const [validation, setValidation] = useState({
        title: "",
        description: "",
    });

    const handleFileChange = e => {
        setformValues({ ...formValues, [e.target.name]: e.target.files[0] });
    };
    const handleChange = (e) => {
        setformValues({ ...formValues, [e.target.name]: e.target.value });
        setInputValue({ ...inputValues, [e.target.name]: e.target.value });
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
        // event.preventDefault();
        // event.stopPropagation();
        if (form.checkValidity() === false) {
            console.log('false');

        }
        if (form.checkValidity() === true) {
            setFormSubmitionStatus('submitted');

            // setShow(false);
            setValidated(true);

            console.log("formValues after submission" , formValues);

            const data = new FormData();
            data.append('faculty_user_id', formValues.faculty_user_id);
            data.append('title', formValues.title);
            data.append('budget', formValues.budget);
            data.append('timeline_year', formValues.timeline_year);
            data.append('timeline_month', formValues.timeline_month);
            data.append('target_beneficiary', formValues.target_beneficiary);
            data.append('number_beneficiary', formValues.number_beneficiary);
            data.append('location', formValues.location);
            data.append('location_states', formValues.location_states);
            data.append('cause', formValues.cause);
            data.append('adherence', formValues.adherence);
            data.append('scale', formValues.scale);
            data.append('customize', formValues.customize);
            data.append('un_sdg_met', formValues.un_sdg_met);
            data.append('description', formValues.description);
            data.append('pdf', formValues.pdf);
            data.append('faculty_name', formValues.faculty_name);
            data.append('faculty_email', formValues.faculty_email);
            console.log("formValues after submission", formValues.pdf);
            axios.post('https://acr.iitm.ac.in/api-https/sociityApi/Proposal/Proposal-file-upload.php', data,
            //  { headers: 
            //     { "Authorization": `Bearer eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJyZXN1bHQiOlt7ImlkIjoxLCJ1c2VybmFtZSI6IlNvY2lpdHlfQWRtaW4iLCJwYXNzd29yZCI6IiQyYiQxMCQ2c0Nac2lCWnJMVnpZSkV4ZkhvbUtPYjFtT2kwbVBwRk44SEtNNlBINnpDNmNhQ3NyWWttSyIsImNyZWF0ZWRfb24iOiIyMDIzLTAyLTAxVDA3OjA3OjQ0LjAwMFoifV0sImlhdCI6MTY3NjAyMTAwNywiZXhwIjoxNjc2MDI0NjA3fQ.vhwdzLVWz3a9NQ7genCcthMcTeQUtilYXurgxQuq3rQ` } 
            // }
            ).then(res => {
                console.log(res);
                toast.success(res.data.message, {
                    position: toast.POSITION.TOP_CENTER,
                    autoClose: 1000
                });
                
                // setData(res.data);
                // setName('');
                // setJob('');
                // setLoading(false);
            }).catch(err => {
                // setLoading(false);
                // setIsError(true);
            });
        }
    };

    return (
        <> 
        
            <div className="p-5 bg-image" style={{ backgroundImage: 'url(../assets/images/bg/bg1.jpg)', height: '300px' }}></div>
            <ToastContainer />
            <MDBContainer >
           
                <MDBCard className='mx-2 mb-5 p-5' style={{ marginTop: '-100px', background: '#f5f5f5'}}>
                    <MDBCardBody className='p-5'>

                        <h2 className="fw-bold mb-5 text-center" style={{color: "black"}}>Add Proposal</h2>&nbsp;
                        <MDBValidation className='row g-3' onSubmit={handleSubmit}>

                            <MDBRow>
                                <MDBCol col='6'>
                                <h6 className='mb-3'>Please add your proposal here.  <a href="https://joyofgiving.alumni.iitm.ac.in/data/proposals/1667283504_proposal_guidelines_csr_portal.pdf" style={{color: "var(--theme-color)"}} text-decoration-underline target="blank">[ View proposal guide here ]</a></h6>

                                    <MDBInput wrapperClass='mb-3' label='Name' name='faculty_name'   type='text' value={name} className='form-control'  style={{background: '#fff'}}  onChange={e => handleChange(e)} required />                                    
                                    <MDBInput wrapperClass='mb-3' label='Email' name='faculty_email' type='email' value={email} className='form-control' style={{background: '#fff'}}  onChange={e => handleChange(e)} required />                                 
                                    <MDBInput wrapperClass='mb-3' label='Title*' name='title'         type='text' style={{background: '#fff'}} onChange={e => handleChange(e)} required />

                                    {validation.title && <p>{validation.title}</p>}
                                    <MDBTextArea wrapperClass='mb-3' label='Brief Description*' required name='description' style={{background: '#fff'}} rows={4} onChange={e => handleChange(e)} />
                                    {validation.description && <p>{validation.description}</p>}

                                    <MDBRow>
                                        <h6>Time Line</h6>
                                        <MDBCol col='6' className='mb-4'>
                                            <Form.Select label='Year' required name='timeline_year' onChange={e => handleChange(e)}>
                                                <option value="">Year</option>
                                                <option value="1">0</option>
                                                <option value="2">1</option>
                                                <option value="3">2</option>
                                                <option value="4">3</option>
                                                <option value="5">4</option>
                                                <option value="6">5</option>
                                                <option value="7">6</option>
                                            </Form.Select>
                                        </MDBCol>

                                        <MDBCol col='6' className='mb-4'>
                                            <Form.Select label='Months' required name='timeline_month' onChange={e => handleChange(e)}>
                                                <option value="">Month</option>
                                                <option value="1">0</option>
                                                <option value="2">1</option>
                                                <option value="3">2</option>
                                                <option value="4">3</option>
                                                <option value="5">4</option>
                                                <option value="6">5</option>
                                                <option value="7">6</option>
                                                <option value="8">7</option>
                                                <option value="9">8</option>
                                                <option value="10">9</option>
                                                <option value="11">10</option>
                                                <option value="12">11</option>
                                            </Form.Select>
                                        </MDBCol>
                                    </MDBRow>
                                    
                                    <MDBInput wrapperClass='mb-3' label='Target Beneficiary*' name='target_beneficiary' required type='text' style={{background: '#fff'}} onChange={e => handleChange(e)} />
                                    <MDBInput wrapperClass='mb-3' label='Number of Beneficiaries' name='number_beneficiary' required type='number' style={{background: '#fff'}} onChange={e => handleChange(e)} />                                    
                                </MDBCol>

                                <MDBCol col='6' >
                                <Form.Label col-md-4 className='text-left mb-3'>Location*   </Form.Label>
                                    <MDBRadio col-md-4 className='anylocation' name='location' value='option1' label='Anywhere in India' required inline onChange={e => handleChange(e)} />
                                    <MDBRadio col-md-4 name='location' value='option2' label='Select States' required inline onChange={e => handleChange(e)} />
                                <Form.Select aria-label="Default select example" className='mb-4' name="location_states" onChange={e => handleChange(e)}>
                                        <option>Select States</option>
                                        <option value="1">Andaman & Nicobar</option>
                                        <option value="2">Andhra Pradesh</option>
                                        <option value="3">Arunachal Pradesh</option>
                                        <option value="4">Assam</option>
                                        <option value="5">Bihar</option>
                                        <option value="6">Chhattisgarh</option>
                                        <option value="7">Goa</option>
                                        <option value="8">Gujarat</option>
                                        <option value="9">Haryana</option>
                                        <option value="10">Himachal Pradesh</option>
                                        <option value="11">Jammu & Kashmir</option>
                                        <option value="12">Jharkhand</option>
                                        <option value="13">Karnataka</option>
                                        <option value="14">Kerala</option>
                                        <option value="15">Madhya Pradesh</option>
                                        <option value="16">Meghalaya</option>
                                        <option value="17">Maharashtra</option>
                                        <option value="18">Manipur</option>
                                        <option value="19">Mizoram</option>
                                        <option value="20">Nagaland</option>
                                        <option value="21">Orissa</option>
                                        <option value="22">Punjab</option>
                                        <option value="23">Rajasthan</option>
                                        <option value="24">Sikkim</option>
                                        <option value="25">Tamilnadu</option>
                                        <option value="26">Tripura</option>
                                        <option value="27">Utter Pradesh</option>
                                        <option value="28">West Bengal</option>
                                    </Form.Select>

                                    <Form.Select aria-label="Default select example" className='mb-4' name="cause" onChange={e => handleChange(e)}>
                                        <option>Key Themes*</option>
                                        <option value="1">Healthcare & Medical technology</option>
                                        <option value="2">Education & Skill Development</option>
                                        <option value="3">Sanitaion & Waste Management</option>
                                        <option value="4">Agriculture & Soil</option>
                                        <option value="5">Water</option>
                                        <option value="6">Environment & Climate Change</option>
                                        <option value="7">Energy & Sustainability</option>
                                        <option value="8">urban Housing & Infrastructure</option>
                                        <option value="9">Heritage</option>
                                        <option value="10">Women Empowerment</option>
                                    </Form.Select>

                                    <MDBFile label='*Upload only pdf files' name='pdf' required className='mb-4' onChange={e => handleFileChange(e)} />

                                    {/* <input type='file' className='form-control' aria-label='file example' accept="image/x-png,image/gif,image/jpeg" required /> */}
                                    <Form.Select aria-label="Default select example" className='mb-4' name="adherence" onChange={e => handleChange(e)}>
                                        <option>Adherence to Schedule VII of the CSR Act (Refer Guidelines for more information)</option>
                                        <option value="1">(i) Health & Sanitation; Poverty Deduction</option>
                                        <option value="2">(ii) Education, Job Skills</option>
                                        <option value="3">(iii) Reducing Inequalities - Women</option>
                                        <option value="4">(iv) Environment Sustainability, Water</option>
                                        <option value="5">(v) National Heritage, Art & Craft</option>
                                        <option value="6">(vi) Armed Force, Veterans</option>
                                        <option value="7">(vii) Rural & Recognised sports training</option>
                                        <option value="8">(viii) PM National Relief Fund</option>
                                        <option value="9">(ix) a) Tech Incubators in Academic Insti.</option>
                                        <option value="10">(ix) b) SDG-related research at IIT</option>
                                        <option value="11">(x) Rural Development</option>
                                        <option value="12">(xi) Slum Area Development</option>
                                    </Form.Select>

                                    <Form.Label column sm="4" col-md-4 className='text-left mb-3'>Scalable</Form.Label>
                                    <MDBRadio col-md-4  className='scalable' name='scale' id='inlineRadio1' value='yes' label='Yes' required inline onChange={e => handleChange(e)} />
                                    <MDBRadio  col-md-4 className='scalable' name='scale' id='inlineRadio2' value='no' label='No' required inline onChange={e => handleChange(e)} />
                                    <br />
                                    <Form.Label column sm="4" className='text-left mb-4'>Customizable</Form.Label>
                                    <MDBRadio className='customizable' name='customize' id='inlineRadio1' value='yes' label='Yes' required inline onChange={e => handleChange(e)} />
                                    <MDBRadio className='customizable' name='customize' id='inlineRadio2' value='no' label='No' required inline onChange={e => handleChange(e)} />

                                    <Form.Select aria-label='Default select example' className='mb-4' name="un_sdg_met" onChange={e => handleChange(e)}>
                                        <option>UN-SDG met</option>
                                        <option value="1">1. No Poverty</option>
                                        <option value="2">2. Zero Hunger</option>
                                        <option value="3">3. Good Health and Well-Being</option>
                                        <option value="4">4. Quality Education</option>
                                        <option value="5">5. Gender Equality</option>
                                        <option value="6">6. Clean Water and Sanitation</option>
                                        <option value="7">7. Affordable and Clean Energy</option>
                                        <option value="8">8. Decent Work and Economic Growth</option>
                                        <option value="9">9. Industry, Innovation and Infrastructure</option>
                                        <option value="10">10. Reduced Inequality</option>
                                        <option value="11">11. Sustainable Cities and Communities</option>
                                        <option value="12">12. Resposible Consumption and Production</option>
                                        <option value="13">13. Climate Action</option>
                                        <option value="14">14. Life Below water</option>
                                        <option value="15">15. Life on Land</option>
                                        <option value="16">16. Peace and Justice Strong Institutions</option>
                                        <option value="17">17. Partnerships to achieve the Goal</option>                                        
                                    </Form.Select>

                                    <Form.Select label='Choose Budget' wrapperClass='mb-3' className='mb-4' name="budget" onChange={e => handleChange(e)} >                                    
                                        <option>Budget</option>
                                        <option value="1">Below 1 CR</option>
                                        <option value="2">1 - 5 CR</option>
                                        <option value="3">6 - 10 CR</option>
                                        <option value="4">11 - 25 CR</option>
                                        <option value="5">25 - 50 CR</option>
                                        <option value="6">Above 50 CR</option>
                                    </Form.Select>  

                                </MDBCol>

                            </MDBRow>
                            <MDBRow>
                                <div className="d-grid gap-2 col-6 mx-auto">
                                    {/* <MDBBtn type='submit' onClick={handleSubmit}>Submit Form</MDBBtn> */}
                                    <MDBBtn type='submit' style={{background: "var(--theme-color)"}}>Submit form</MDBBtn>

                                </div>
                            </MDBRow>
                        </MDBValidation>
                    </MDBCardBody>
                </MDBCard>
            </MDBContainer>
        </>
    );
}
export default AddProposals;