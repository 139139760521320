import './testimonial.css'
import { TestimonialsData } from "../Content/TestimonialsData.js";
import { useState } from "react";
import leftArrow from "../assets/images/bg/leftArrow.png";
import rightArrow from "../assets/images/bg/rightArrow.png";
import { motion } from "framer-motion";

export default function Testimonials() {
  const transition = { type: "spring", duration: 3 };
  const [selected, setSelected] = useState(0);
  const tLength = TestimonialsData.length;

  return (
    <>
      <section className="animated-testi">
      <div class="container largeContainer">

        <div className="testimonials">
                <div className="left-t">                  
                  <span>What they say about us</span>
                  <span>Our CSR Champions</span>
                  <span></span>
                  <motion.span
                    key={selected}
                    initial={{ opacity: 0, x: -100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: 100 }}
                    transition={transition}
                  >
                    {TestimonialsData[selected].review}
                  </motion.span>
                  <span>
                    <span style={{ color: "blue" }}>
                      {TestimonialsData[selected].name}
                    </span>{" "}
                    - {TestimonialsData[selected].status}
                  </span>
                </div>

                <div className="right-t">
                  <motion.div
                    initial={{ opacity: 0, x: -100 }}
                    transition={{ ...transition, duration: 2 }}
                    whileInView={{ opacity: 1, x: 0 }}
                  ></motion.div>
                  <motion.div
                    initial={{ opacity: 0, x: 100 }}
                    transition={{ ...transition, duration: 2 }}
                    whileInView={{ opacity: 1, x: 0 }}
                  ></motion.div>
                  <motion.img
                    key={selected}
                    initial={{ opacity: 0, x: 100 }}
                    animate={{ opacity: 1, x: 0 }}
                    exit={{ opacity: 0, x: -100 }}
                    transition={transition}
                    src={TestimonialsData[selected].image}
                    alt=""
                  />
                  <div className="arrows">
                    <img
                      onClick={() => {
                        selected === 0
                          ? setSelected(tLength - 1)
                          : setSelected((prev) => prev - 1);
                      }}
                      src={leftArrow}
                      alt=""
                    />
                    <img
                      onClick={() => {
                        selected === tLength - 1
                          ? setSelected(0)
                          : setSelected((prev) => prev + 1);
                      }}
                      src={rightArrow}
                      alt=""
                    />
                  </div>
                </div>
          </div>
        </div>
      </section>
    </>
  )
};