import React, { useEffect } from 'react'
import ProjectSlider from '../Component/ProjectSlider'
import Preloader from '../Component/Preloader'
import Header from '../Component/Header'
import ProjectContent from '../Content/ProjectContent'
import Footer from '../Component/Footer'
import { useLocation } from "react-router-dom";

function Projects() {
  const {pathname} = useLocation();

  useEffect(() => {
      window.scrollTo({ top: "0", behavior: "smooth"})
  },[pathname])
  return (
    <>
    <Preloader/>
    <Header/>
    <ProjectSlider/>
    <ProjectContent/>
    <Footer/>
</>
  )
}

export default Projects
