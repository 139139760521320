import React from "react";
import $ from "jquery";

class Preloader extends React.Component {
    componentDidMount() {
        //$(window).on('load', function () {
            var preload = $('.preloader');
            if (preload.length > 0) {
                preload.delay(800).fadeOut('slow');
            }
       // });



    }
    render() {
        return (
            <div className="preloader clock text-center">
                <div className="queraLoader">
                    <div className="loaderO">
                        <span>S</span>
                        <span>O</span>
                        <span>C</span>
                        <span>I</span>
                        <span>I</span>
                        <span>T</span>
                        <span>Y</span>
                    </div>
                </div>
            </div>
        )
    }
}
export default Preloader;