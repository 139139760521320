import React from "react";

function BenefitsContent() {
    return (
        <>
            <section class="partnerh">
                <div class="container-fluid">
                    <h1 class="title02">Benefits</h1>
                </div>
            </section>
              
              
              {/* <section class="page_banner" style={{ backgroundImage: "url(assets/images/bg/4.jpg)" }}>
              <div class="overlay"></div>
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-md-12 text-center">
                            <h2 class="banner-title">Benefits</h2>

                        </div>
                    </div>
                </div> 
            </section>  */}


            <section class="singleBlog1 bpPad">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-12">                           
                                    <div class="sic_the_content clearfix">
                                        <p style={{"color":"#000"}}>Contributions made towards scientific research to the Indian Institute of Technology Madras, are exempted under Section 35(1)(ii) of the Income Tax Act, 1961. CSR contributions are eligible for 100% tax exemption under Section 80G (2) (a) (iiif) of the Income Tax Act, 1961. IIT Madras is also exempted from the provision of the Foreign Contribution Regulation Act 1976 as per Govt. of India, Ministry of Home Affairs letter No.II/21022/14(3)94-FCRA.I Dated 23rd December 1994.</p>
                                    <div class="sic_the_content clearfix">
                                    <h4 class="benet">Some factors that make IIT Madras a preferred CSR Partner: </h4>
                                        <ul class="listItem lIleft">
                                            <li>Contributions qualify as CSR Expenditure under Schedule VII of the CSR Act of the Government of India</li>
                                            <li>100% Tax Exemption under 80G, with no limit </li>
                                            <li>Projects that fulfil UN-SDGs </li>
                                            <li>Globally renowned expertise across a range of topics </li>
                                            <li>Innovative technology-driven solutions for social impact </li>
                                            <li>Customized CSR solutions for progressive Corporates </li>                                         
                                            <li>Professional CSR Stewardship team</li>
                                            <li>Prestigious partnership with India's best</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
            </section>

        </>
    )
}

export default BenefitsContent;