import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import WaterContent from "../Content/WaterContent";

function Water(){
    return(
        <>
        <Preloader/>
        <Header/>
        <WaterContent/>
        <Footer/>
        </>
    )
}
export default Water;