import React, { useEffect } from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import HealthcareContent from "../Content/HealthcareContent";
import { useLocation } from "react-router-dom";

function Healthcare(){
    const {pathname} = useLocation();

    useEffect(() => {
        window.scrollTo({ top: "0", behavior: "smooth"})
    },[pathname])
    return(
        <>
        <Preloader/>        
        <Header/>
        <HealthcareContent/>
        <Footer/>
        </>
    )
}
export default Healthcare;