import React, { useState } from 'react';
import image1 from '../assets/images/coe.png';
import image2 from '../assets/images/scholar.png';
import image3 from '../assets/images/research.png';

const Tabs = () => {

    const [currentTab, setCurrentTab] = useState('1');
    const tabs = [
        {
            id: 1,
            tabTitle: 'CoE',
            title: 'CoE',
            content: 'Whether data science and AI, advanced materials and manufacturing, steel technology, railway or battery engineering, IITM’s Centres of Excellence undertake research in high-impact areas. Involving cross-disciplinary expertise derived from collaborations among faculty members, students and industry professionals, these centres enrich the Institute’s innovation ecosystem through path-breaking and socially relevant research.',
            image: image1
        },
        {
            id: 2,
            tabTitle: 'Scholarship',
            title: 'Scholarship',
            content: 'IITM for All is our mission. To align with this goal, we are committed to supporting students through the Merit Cum Means Scholarship. This scholarship aims to alleviate the financial concerns of future students, ensuring that financial constraints do not hinder their pursuit of education. By providing this support, we strive to empower students to overcome obstacles and take confident steps towards a brighter future.',
            image: image2
        },
        {
            id: 3,
            tabTitle: 'Research',
            title: 'Research',
            content: 'New technologies are transforming our daily lives – the way we live and work, the way we interact and socialise, and the way we make decisions. IITM is not only leading the research and development of such technologies, but is also helping implement inclusive solutions at scale, which are transparent and have the potential to effect enormous social good.',
            image: image3
        }
    ];

    const handleTabClick = (e) => {
        setCurrentTab(e.target.id);
    }

    return (
        <div className='container' style={{ marginBottom: "10px" }}>
            <div className='tabs'>
                {tabs.map((tab, i) =>
                    <button key={i} id={tab.id} disabled={currentTab === `${tab.id}`} onClick={(handleTabClick)}>{tab.tabTitle}</button>
                )}
            </div>

            <div className='container tab-row'>
            {tabs.map((tab, i) =>
                <div key={i}>
                    {currentTab === `${tab.id}` && 
                    <div className='row'>
                        <div className='col-lg-6'>
                            <img src={tab.image} alt='tabimage' style={{width: "100%"}}/>
                        </div>
                        <div className='col-lg-6 content'>
                            <p className='title'>{tab.title}</p>
                            <p>{tab.content}</p>
                        </div> 
                    </div>        
                    }
                </div>
                )}
            </div>           
        </div>
    );
}

export default Tabs;
