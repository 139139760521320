import React from "react";

function Header() {     

    return (
        <header className="header01 isSticky">
            <div className="container-fluid largeContainer">
                <div className="row">
                    <div className="col-lg-12">
                        <div className="navbar01">
                            <div className="logo1">
                                <a href="/">
                                    <img src="assets/images/logo.png" alt="Sociity"/>
                                </a>
                            </div>
                            <a href="/" className="menu_btn"><i className="twi-bars2"></i></a>
                            <nav className="mainMenu">
                                <ul>
                                    <li>
                                        <a href="/">Home</a>
                                    </li>                                 
                                    <li className="menu-item-has-children">
                                        <a href="#/projects">CSR Opportunities</a>
                                        <ul className="sub-menu">
                                            <li><a href="#/health">Open Projects</a></li>
                                            <li><a href="#/noteworthy">Noteworthy Projects</a></li>
                                            <li><a href="#/researchareas">Research Areas @ IITM</a></li>
                                        </ul>
                                    </li>

                                    <li className="menu-item-has-children">
                                        <a href="/">News</a>
                                        <ul className="sub-menu">
                                            <li><a href="#/events">Events</a></li>
                                            <li><a href="/">Blogs</a></li>
                                            <li><a href="/" alt="">News Letters</a></li>
                                        </ul>
                                    </li>  
                                    <li className="menu-item-has-children">
                                        <a href="/">About Us</a>
                                        <ul className="sub-menu">
                                            <li><a href="#/team">Our Team</a></li>
                                            <li><a href="#/ourwork">Our Work</a></li>
                                        </ul>
                                    </li>                            
                                    <li>
                                        <a href="#/facultyproposals">Faculty Login</a>  
                                    </li>
                                </ul>
                            </nav>
                            <div className="accessNav">
                                <a className="berpo_btn" href="#/contact">                                    
                                <span className="bp-shape"></span>
                                <span className="bp-shape"></span>                                
                                    <span className="bp-shape"></span>
                                    <span className="bp-shape"></span>
                                    <span className="bp-text">Get in touch</span>
                                </a>
                            </div>
                            <div className="logo">
                                <a href="index.html">
                                    <img src="assets/images/iitmlogo.png" alt="Sociity" />
                                </a>
                            </div>
                            
                        </div>
                    </div>
                </div>
            </div>
        </header>
    )
}
export default Header;