import axios from 'axios';
import React, {useState, useEffect} from 'react';
import Form from 'react-bootstrap/Form';
import FacultyProposals from '../Pages/FacultyProposal';
import {
    MDBValidation,
    MDBValidationItem,
    MDBBtn,
    MDBContainer,
    MDBCard,
    MDBCardBody,
    MDBCol,
    MDBRow,
    MDBInput,
    MDBTextArea,
    MDBRadio,
    MDBFile
} from 'mdb-react-ui-kit';
import {ToastContainer, toast} from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function LoginProposals(props) {

    const [formSubmitionStatus, setFormSubmitionStatus] = useState('notSubmitted');
    const [validated, setValidated] = useState(false);
    const [successLogin, setsuccessLogin] = useState(0);
    const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated") || false));
    const [formValues, setformValues] = useState();
    const[username,setusername] = useState();
    const[email,setemail] = useState();
    const [inputValues, setInputValue] = useState({title: "", description: ""});
    const [validation, setValidation] = useState({title: "", description: ""});

    const handleFileChange = e => {
        setformValues({
            ...formValues,
            [e.target.name]: e.target.files[0]
        });
    };
    const handleChange = (e) => {
        setformValues({
            ...formValues,
            [e.target.name]: e.target.value
        });
        setInputValue({
            ...inputValues,
            [e.target.name]: e.target.value
        });
    }

    useEffect(() => {
        //console.log('World city updated', successLogin)
        if (successLogin == "0") {
            setauthenticated(true);
            props.sendData(authenticated);            
        }
        
        if (successLogin == "1") {
            setauthenticated(false);
            props.sendData(authenticated);
            props.sendUsername(username);
            props.sendEmail(email);
            console.log("LDAP username is successLogin", username);
            console.log("LDAP email is successLogin", email);
        }
    }, [successLogin])

    const handleSubmit = async (e) => {
        e.preventDefault();

        const form = e.currentTarget;
            if (form.checkValidity() === false) {
            console.log('false');
        }
        if (form.checkValidity() === true) {
            setFormSubmitionStatus('submitted');
            setValidated(true);

            const data = new FormData();
            data.append('uname', formValues.username);
            data.append('pwd', formValues.password);

            var CryptoJS = require("crypto-js");
            var encrypted = CryptoJS.AES.encrypt(formValues.password, "*!SOCIITY_ROF_ETACITNEHTUA_MTII**").toString();
           
            console.log('encrypted', encrypted);

            axios.post('https://acr.iitm.ac.in/nodue/sociityauthenticate.php', {
                username: formValues.username,
                password: encrypted
            }).then(response => {

                var success = response.data.success;
                console.log("success vaiable is ", response.data.success);
                console.log("LDAP username is ", response.data.username);
                console.log("LDAP email is ", response.data.email);
                setsuccessLogin(response.data.success);
                setusername(response.data.username);
                setemail(response.data.email);
            
            }).catch(error => {
                               
            });
        }
    };

    return(
    <> 
    <div className="p-5 bg-image" style={{ backgroundImage: 'url(../assets/images/bg/bg1.jpg)', height: '300px' }}></div>
    <ToastContainer/> 
        <MDBContainer> 
            <MDBCard className = 'mx-1 mb-5 p-1' style = {{ marginTop: '-100px', background: '#f5f5f5'}} > 
                <MDBCardBody className='p-5 '> <h2 className = "fw-bold mb-5 text-center" style = {{ color: '#000000'}} >Login with LDAP account</h2>
                    <MDBValidation className='row g-3' onSubmit={handleSubmit}>
                    <MDBRow>
                        <MDBCol className="col-sm">
                        <h6 className='mb-3'>Please add your proposal here...  <a href="https://joyofgiving.alumni.iitm.ac.in/data/proposals/1667283504_proposal_guidelines_csr_portal.pdf" style={{color: "var(--theme-color)"}} target="blank">[ View proposal guide here ]</a></h6>                           
                            <MDBInput wrapperClass='mb-3' label='Username' name='username' className='form-control fw-bold' type='text' onChange={e =>handleChange(e)} required/> 
                            <MDBInput wrapperClass='mb-3' label='Password' name='password' className='form-control fw-bold' type='text'  onChange={e => handleChange(e)}required/> 
                        </MDBCol> 
                    </MDBRow>
                        <MDBRow>
                                <div className="col-lg-6 col-md-6 col-sm-6">  
                                <MDBBtn type='submit' style={{background: "var(--theme-color)"}}>Submit form</MDBBtn>
                            </div> 
                        </MDBRow>
                    </MDBValidation>
                </MDBCardBody> 
            </MDBCard>
        </MDBContainer> 
    </>
);
} export default LoginProposals;
