import React from 'react'
import './CSRSubmit2023.css'
import Header from '../Component/Header';
import Footer from '../Component/Footer';

function CSRSubmit2023() {
    return (
        <>
            <Header />
            <section className="healthhead">
                <div className="container-fluid">
                    <h2 className='gallery-head' style={{ color: "black" }}>CSR SUMMIT 2023</h2>
                </div>
            </section>
            <section className='container largeContainer'>
                <div className="gallery-container1">
                <div>
                    <img src="assets/images/csr2023/Stalls/PS3850.jpg" alt="csr2023-1" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A8375.jpg" alt="csr2023-2" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9607.jpg" alt="csr2023-3" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9618.jpg" alt="csr2023-4" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9625.jpg" alt="csr2023-5" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9639.jpg" alt="csr2023-6" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9642.jpg" alt="csr2023-7" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9673.jpg" alt="csr2023-8" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9678.jpg" alt="csr2023-9" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9683.jpg" alt="csr2023-10" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/2F9A9702.jpg" alt="csr2023-11" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/PS3867.jpg" alt="csr2023-12" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Stalls/PS3917.jpg" alt="csr2023-13" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/2F9A8981.jpg" alt="csr2023-14" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4090.jpg" alt="csr2023-15" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4102.jpg" alt="csr2023-16" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4121.jpg" alt="csr2023-17" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4155.jpg" alt="csr2023-18" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4157.jpg" alt="csr2023-19" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4159.jpg" alt="csr2023-20" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4161.jpg" alt="csr2023-21" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4162.jpg" alt="csr2023-22" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4164.jpg" alt="csr2023-23" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4166.jpg" alt="csr2023-24" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CXO/PS4168.jpg" alt="csr2023-25" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/2F9A9787.jpg" alt="csr2023-26" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/2F9A9847.jpg" alt="csr2023-27" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4606.jpg" alt="csr2023-27" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4667.jpg" alt="csr2023-28" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4669.jpg" alt="csr2023-29" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4671.jpg" alt="csr2023-30" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4673.jpg" alt="csr2023-31" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4676.jpg" alt="csr2023-32" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4682.jpg" alt="csr2023-33" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4686.jpg" alt="csr2023-34" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4687.jpg" alt="csr2023-35" />
                </div>
                <div>
                    <img src="assets/images/csr2023/CSRHeads/PS4695.jpg" alt="csr2023-36" />
                </div>


                <div>
                    <img src="assets/images/csr2023/Winners/Bharath.jpg" alt="csr2023-30" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Winners/CNH.jpg" alt="csr2023-31" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Winners/LIC.jpg" alt="csr2023-32" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Winners/Marutisuzuki.jpg" alt="csr2023-33" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Winners/Portescap.jpg" alt="csr2023-34" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Winners/TATASteel.jpg" alt="csr2023-35" />
                </div>
                <div>
                    <img src="assets/images/csr2023/Winners/VIZUARA.jpg" alt="csr2023-36" />
                </div>
                </div>
            </section>
            <Footer />
        </>
    )
}

export default CSRSubmit2023;
