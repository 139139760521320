import React from 'react'
import { useNavigate } from 'react-router-dom';
import './ProjectContent.css'
import Tabs from '../Content/Tabs.js';
import Tabnew from './Tabnew.js';

function ProjectContent() {
    const navigate = useNavigate();

    const navigateProjects = () => {        
        navigate('/health');
    };
    const navigateNoteworthy = () => {        
        navigate('/noteworthy');
    };

    return (
        <>
            <section>
                <div className='container'>
                    <div className='row'>
                        <div className='col-lg-12'>
                            <h1 className='project-tech'>Technology for Social impact</h1>
                            <p className='project-tech-p'>The pace of advance towards new technology and socially impactful innovation is invariably seen to pick up when the scientific community and industry recognise that their interests are aligned. Given that societal impact and sustainability have become the major focus areas of business today, these areas of common concern have grown in number and have become more exciting. Therefore, industrial contribution to long-term R&D has increased, and universities, such as IITM have become trusted partners in industry collaboration, as well as hubs of incubation so that technology transfer can take place at scale.</p>
                        </div>
                    </div>
                </div>
            </section>
            <section className='proj-banner'>
                <div>
                    <div className="proj-content">
                        <h1>Explore CSR Projects</h1>
                        {/* <p>By using our website you can design your own website very beautiful. <br /> Lorem, ipsum dolor sit amet consectetur adipisicing elit. </p> */}
                        <button className='probtn' type='button' value='Open Projects' onClick={navigateProjects} >Open Projects</button>
                        <button className='probtn' type='button' value='Noteworthy Projects' onClick={navigateNoteworthy}>Noteworthy Projects</button>     
                    </div>                    
                </div>
            </section>

            <Tabs/>

{/* <Tabnew/> */}

            
        </>
    )
}

export default ProjectContent
