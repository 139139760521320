import React, { useState } from 'react';
import axios from 'axios';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

function ContactContent() {
    const [fname, setFname] = useState('');
    const [lname, setLname] = useState('');
    const [companyname, setCompanyname] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const handleSubmit = async (e) => {
        e.preventDefault();
        
        if (!fname || !lname || !companyname || !email || !phone || !message) {
            toast.error("All fields are required!");
            return;
        }

        //const url = 'http://localhost/sociityApi/contactus.php';
        const url = 'https://sociity.in/sociityApi/contactus.php';
        let fData = new FormData();
        fData.append('fname', fname);
        fData.append('lname', lname);
        fData.append('companyname', companyname);
        fData.append('email', email);
        fData.append('phone', phone);
        fData.append('message', message);

        try {
            const response = await axios.post(url, fData);
            //toast.success(response.data.message);
            toast.success("Your message has been sent!");

            setFname('');
            setLname('');
            setCompanyname('');
            setEmail('');
            setPhone('');
            setMessage('');

        } catch (error) {
            toast.error("An error occurred while sending your message.");
        }
    };
    return (
        <>
            <section class="conInfoSec">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="icon_box_07 text-center">
                                <div class="ibMeta">
                                    <i class="bpro-location"></i>
                                </div>
                                <h3>Address</h3>
                                <p>Office of Institutional Advancement,
                                    2nd floor, <br />IC&SR Building,
                                    IIT Madras, <br />Chennai - 600 036.<br /></p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="icon_box_07 text-center">
                                <div class="ibMeta">
                                    <i class="bpro-mail-3"></i>
                                </div>
                                <h3>Mail</h3>
                                <p>partner@sociity.in</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="icon_box_07 text-center">
                                <div class="ibMeta">
                                    <i class="bpro-clock"></i>
                                </div>
                                <h3>Office Hours</h3>
                                <p>9:30 am - 5:30 pm <br /> Monday to Friday</p>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="icon_box_07 text-center">
                                <div class="ibMeta">
                                    <i class="bpro-phone-call-1"></i>
                                </div>
                                <h3>Phone</h3>
                                <p>044 2257 8874 <br /> 044 2257 8379</p>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-md-12">
                            <div class="cnborder"></div>
                        </div>
                    </div>
                </div>
            </section>

            <section class="mapsSection02">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-6 col-md-12">
                            <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3887.689406824759!2d80.22991331534328!3d12.991706617950651!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3a526780281bed21%3A0xd860742c9f32f3f4!2sIC%26SR%20Building%20IIT%20Madras!5e0!3m2!1sen!2sin!4v1677474370579!5m2!1sen!2sin" width="600" height="450" style={{ border: 0 }} allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="contact_form contactPageForm">
                                <h2 class="secTitle02" style={{alignItems: "center"}}>Get in touch</h2>
                               
                                <form className="row" id="contact_form">
                                    <div className="col-lg-12 col-md-12">
                                        <input
                                            className="required"
                                            type="text"
                                            name="fname"
                                            value={fname}
                                            onChange={(e) => setFname(e.target.value)}
                                            placeholder="First Name"
                                        />
                                        <input
                                            className="required"
                                            type="text"
                                            name="lname"
                                            value={lname}
                                            onChange={(e) => setLname(e.target.value)}
                                            placeholder="Last Name"
                                        />
                                        <input
                                            className="required"
                                            type="text"
                                            name="companyname"
                                            value={companyname}
                                            onChange={(e) => setCompanyname(e.target.value)}
                                            placeholder="Company Name"
                                        />
                                        <input
                                            className="required"
                                            type="email"
                                            name="email"
                                            value={email}
                                            onChange={(e) => setEmail(e.target.value)}
                                            placeholder="Email Address"
                                        />
                                        <input
                                            className="required"
                                            type="tel"
                                            name="phone"
                                            value={phone}
                                            onChange={(e) => setPhone(e.target.value)}
                                            placeholder="Phone Number"
                                        />
                                        <textarea
                                            className="required"
                                            name="message"
                                            value={message}
                                            onChange={(e) => setMessage(e.target.value)}
                                            placeholder="Any additional information you would like to share with us"
                                        ></textarea>
                                        <button
                                            type="button"
                                            className="berpo_btn"
                                            name="send"
                                            id="send"
                                            value="SEND"
                                            onClick={handleSubmit}
                                        >
                                            <span className="bp-text">I'd like to know more</span>
                                        </button>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <ToastContainer />
        </>
    )
}

export default ContactContent;