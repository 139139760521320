import React from "react";

function WorkContent() {
    return (
        <>     

            <section className="singleBlog bpPad">
                <div className="container largeContainer"><h1 className="p_title">Our Work</h1> 
                    <div className="row">
                        <div className="col-lg-8">
                            <div className="sic_the_content clearfix">                                
                                <p style={{color: "#000"}}>IIT Madras has emerged as a market leader in the unique space of leveraging technology to drive social impact through its Corporate Social Responsibility (CSR) collaborations. With 180+ CSR partners and CSR projects worth Rs. 240+ crores in the past few years, The Institute has been pioneering technology-driven social impact on a range of themes, from energy to education, agriculture to artificial intelligence, water to waste management, health to housing, centers of excellence to community development, among others.</p>
                                <p style={{color: "#000"}}>IIT Madras collaborates with corporates, PSUs and various institutions falling under the gamut of the CSR Act on a range of technology-driven CSR projects. Our CSR project collaboration opportunities pan across the UN-SDGs (United Nations’ Sustainable Development Goals). The fundraising activities are spearheaded by the Office of Alumni and Corporate Relations (ACR), led by the Office of Institutional Advancement. Corporates can choose to plug-and-play various technological solutions into their existing projects or reach out to us for new projects.</p>                            
                                <p style={{color: "#000"}}>The institute has raised a historic all-time high sum of Rs. 131 crore from alumni, donors and corporate firms during the financial year of 2021-22 to further its philanthropic and socially-relevant projects.</p>
                            </div>
                        </div>
                        <div className="col-lg-4">
                            <div style={{alignItems: "center", justifyContent: "center"}}>
                                <img style={{width: "100%", padding: "5px 0", position: "relative"}} className="workimage" src="assets/images/wrk.png" alt=""/>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
            <section className="partnerh">
                <div className="container-fluid">
                    <h1 className="title02">Benefits</h1>
                </div>
            </section>
            <section className="singleBlog1 bpPad">
                <div className="container largeContainer">
                    <div className="row">
                        <div className="col-lg-12">                           
                                    <div className="sic_the_content clearfix">
                                        <p style={{"color":"#000"}}>Contributions made towards scientific research to the Indian Institute of Technology Madras, are exempted under Section 35(1)(ii) of the Income Tax Act, 1961. CSR contributions are eligible for 100% tax exemption under Section 80G (2) (a) (iiif) of the Income Tax Act, 1961. IIT Madras is also exempted from the provision of the Foreign Contribution Regulation Act 1976 as per Govt. of India, Ministry of Home Affairs letter No.II/21022/14(3)94-FCRA.I Dated 23rd December 1994.</p>
                                    <div className="sic_the_content clearfix">
                                    <h4 className="benet">Some factors that make IIT Madras a preferred CSR Partner: </h4>
                                        <ul className="listItem lIleft">
                                            <li>Contributions qualify as CSR Expenditure under Schedule VII of the CSR Act of the Government of India</li>
                                            <li>100% Tax Exemption under 80G, with no limit </li>
                                            <li>Projects that fulfil UN-SDGs </li>
                                            <li>Globally renowned expertise across a range of topics </li>
                                            <li>Innovative technology-driven solutions for social impact </li>
                                            <li>Customized CSR solutions for progressive Corporates </li>                                         
                                            <li>Professional CSR Stewardship team</li>
                                            <li>Prestigious partnership with India's best</li>
                                        </ul>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>                
            </section>
        </>
    )
}
export default WorkContent;