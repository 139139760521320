import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import BlogsContent from "../Content/BlogsContent";

function Blogs() {
  return (
    <>
    <Preloader/>
    <Header/>
    <BlogsContent/>
    <Footer/>
    </>
  )
}

export default Blogs
