import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import EnergyContent from "../Content/EnergyContent";

function Energy(){
    return(
        <>
        <Preloader/>
        <Header/>
        <EnergyContent/>
        <Footer/>
        </>
    )
}
export default Energy;