import React from "react";
import Preloader from "../Component/Preloader";
import Header from "../Component/Header";
import Footer from "../Component/Footer";
import TeamContentNew from "../Content/TeamContentNew";

function Team() {
    return (
        <>
            <Preloader />
            <Header />
            <TeamContentNew/>
            <Footer/>

        </>
    )
}
export default Team;