import React from 'react'

export default function ResearchNew() {
  return (
    <>
      <section class="service_section_09">
        <div class="container largeContainer">
          <div class="row">
            <div class="col-xl-12 text-center">
              <h2 class="secTitle10">Research Areas @ IITM</h2>
            </div>
          </div>
          <div class="row">
          <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/energy.png" alt=""/>
                </div>
                <h3>ENERGY, PROPULSION, RENEWABLES</h3>
                <p>The Centers in this cluster will work to advance sustainable gas turbine technology, Carbon Capture, Utilization, and Storage, source technology development for microgrid operation, photo- and electrochemical research, technology development and commercialization for Energy Storage, Management, and Integration, etc.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/sensingandvision.png" alt="" />
                </div>
                <h3>SENSING AND VISION</h3>
                <p>A holistic approach to removing bottlenecks in accurate and precise sensing/imaging in healthcare, creating algorithms for vision enabled AR and new 3D experiences in mobile cameras, cutting-edge technologies such as ubiquitious sensing, continuous distributed sensing, and so on, and understanding the interaction challenges in touch-enabled experiences and VR systems will be among the Centres' focus areas.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/bignetwork.png" alt=""/>
                </div>
                <h3>BIG NETWORK</h3>
                <p>The Centres will work on developing and implementing connected and integrated mobility solutions for highly heterogeneous traffic and transit systems, as well as technological breakthroughs in the field of Online Machine Learning and understanding the governing principles of naturally occurring systems, as well as the development of the next generation of man-made networks.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/safetyscience.png" alt=""/>
                </div>
                <h3>SAFETY SCIENCE & SYSTEMS</h3>
                <p>The Centres' focus areas will be Road Safety, Patient Safety, Industrial Safety, & Standards through data-driven policy-making, Human Factor Engineering, Behavioral & Cognitive Research, and Rehabilitation Engineering, as well as gathering input and expertise from organisations in the areas of structural integrity assessment and failure investigation of safety critical structures & components.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/quantumscience.png" alt=""/>
                </div>
                <h3>QUANTUM SCIENCE AND TECHNOLOGY</h3>
                <p>Some of the areas of focus will include developing energy efficient quantum electronic devices from large area CVD 2D layered materials, exploring & identifying entanglement measures & relative entropies in quantum field theory, areas in the verticals of quantum networks & quantum computing, and superconductivity in boron-doped diamond(BDD), among others.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/circulareconomy.png" alt=""/>
                </div>
                <h3>CIRCULAR ECONOMY</h3>
                <p>The Centres will work on the development and implementation of low-carbon, lean construction technologies, as well as the exploration of fundamental scientific questions that will lead to a better understanding of the processes that govern the availability, quality, and treatment of water resources.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/oceantech.png" alt=""/>
                </div>
                <h3>OCEAN TECHNOLOGY</h3>
                <p>The goal of the research is to evaluate the physical processes taking place in the ocean using hybrid computational techniques, technical advancements, and the development of novel concepts and innovations for the port and maritime industries.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/manufacturing.png" alt=""/>
                </div>
                <h3>ADVANCED MANUFACTURING</h3>
                <p>The Centres in this cluster will work to develop new technologies for advanced laser applications and nano-manufacturing, as well as to develop technologies for Extra Terrestrial Manufacturing and to serve as a pyrometallurgy research and development centre.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/humanity.png" alt=""/>
                </div>
                <h3>HUMANITIES AND BUSINESS</h3>
                <p>Some of the research projects of this hub involve the creation, development, and financing of innovations and start-ups as well as the investigation of the connections between memory, cognition, corporeality, and representation using a combination of AR/VR technology and Cognitive Studies.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/microelec.png" alt=""/>
                </div>
                <h3>MICROELECTRONICS AND INTEGRATED CIRCUITS</h3>
                <p>Next-generation high-speed, non-volatile memory and computing technologies, as well as dependable processes for making e-mode AllnN/GaN Fin MIS-HEMTs with high current drive, are among the topics of focus.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/medicine.png" alt=""/>
                </div>
                <h3>MEDICINE & BIOLOGY</h3>
                <p>The research areas include genomic analysis of pancreatic cancers, multiscale digital neuroanatomy with an engineering focus, and drug development for noncommunicable diseases such as cancer, diabetes, stroke, and cardiovascular disease.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/physics.png" alt=""/>
                </div>
                <h3>PHYSICS</h3>
                <p>The Centres' main goals will be to conduct experiments in experimental particle physics that set world records, as well as research into classical general relativity, semi-classical and quantum gravity, cosmology, and eventually string theory-related concepts.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/medicaltech.png" alt=""/>
                </div>
                <h3>MEDICAL TECHNOLOGY</h3>
                <p>Consulting, advanced training, and research in the areas of medical device regulations, standards, calibration, non-invasive hemodynamics for early vascular health assessment, micro nano bio fluidics will be the primary focus areas.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/maths.png" alt=""/>
                </div>
                <h3>MATHEMATICS AND CYBERSECURITY</h3>
                <p>The emphasis will be on developing a broader understanding of algebraic varieties, developing reliable and powerful methods to secure digital data, and leading research in Quantum Analysis.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/complexsys.png" alt=""/>
                </div>
                <h3>COMPLEX SYSTEMS</h3>
                <p>The Centres' primary goal is to use recent advances in data science to develop mathematical models for large ordered complex systems and to investigate critical transitions and extreme events in flow and energy systems in engineering and nature.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/earthscience.png" alt=""/>
                </div>
                <h3>EARTH SCIENCES</h3>
                <p>The Centers will pioneer multidisciplinary research in the field of subsurface mechanics & independently meet the demand for top-notch research in atmospheric & climate sciences, bridging the gap between fundamental science and enhanced ocean models and forecasts.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/material.png" alt=""/>
                </div>
                <h3>ADVANCED MATERIAL</h3>
                <p>The Centers' primary goals will be to make significant contributions to various aspects of disease diagnosis and treatment, to conduct interdisciplinary research on evaporation of complex fluids, and to develop components for image-based material property evaluation.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/autonomous.png" alt=""/>
                </div>
                <h3>AUTONOMOUS SYSTEMS</h3>
                <p>The centres will concentrate on developing cutting-edge systems and technologies for autonomous operation of engineering systems, as well as research in marine automation.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/chemistry.png" alt=""/>
                </div>
                <h3>CHEMISTRY</h3>
                <p>One of the centres will mimic biosystems with chemical systems and use the confined space to study chemical reactivity, while the other will develop chiral technologies for use by academia and industry.</p>
                </div>
            </div>
            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/microscopy.png" alt=""/>
                </div>
                <h3>MICROSCOPY</h3>
                <p>Focus topics include producing high-performance materials specifically suited for faults and investigating materials using cutting-edge microscopy technologies.</p>
                </div>
            </div>


            <div class="col-lg-4 col-md-6">
              <div class="service_item_10">
                <div class="ibMeta">
                  <img src="assets/images/research/ai.png" alt=""/>
                </div>
                <h3>AI AND DATA SCIENCES</h3>
                <p>The Centers will focus on accurate and secure data modelling, deployable AI, scientific research in sports data-driven disease, and clinical research.</p>
                </div>
            </div>

          </div>
        </div>
      </section>
    </>
  )
}
