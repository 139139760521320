import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import ContactContent from "../Content/ContactContent";

function Contact(){
    return(
        <>
        <Preloader/>
        <Header/>
        <ContactContent/>
        <Footer/>
        </>
    )
}
export default Contact;