import image1 from "../assets/images/bg/pratush.jpg";
import image2 from "../assets/images/bg/anurag.png";
import image3 from "../assets/images/bg/narendra.jpg";
import image4 from "../assets/images/bg/usha.jpg";
import image5 from "../assets/images/bg/rathin.jpg";
import image6 from "../assets/images/bg/yogesh.jpg";
import image7 from "../assets/images/bg/sarojini.jpg";

export const TestimonialsData = [
  {
    image: image1,
    review: "IIT Madras is engaged in remarkable research, technology-driven initiatives that have a significant environmental and social impact, while also fostering innovation. These efforts align perfectly with our honorable PM's 'Make in India' campaign, offering excellent CSR synergies for progressive corporates aiming to create lasting change. Our partnership with IIT Madras on futuristic projects like 5G and Quantum exemplifies our ambition to bring the most innovative, breakthrough solutions and invest in capabilities early on to stay ahead of the curve.",
    name: 'Mr Pratyush Panda',
    status: 'Head ESG | LTIMindtree'
  },
  {
    image: image2,
    review: "I do not believe in creating 'islands' of change. An intervention should be able to impact society at scale, for it to have lasting impact. One of the main ways this can happen is through Technology. I commend IIT Madras for their socially impactful initiatives by leveraging technology and for creating awareness amongst Corporate stakeholders on the various CSR collaboration opportunities across important themes. I encourage everyone to make a visit to IIT Madras, to interact with the technology experts and witness the remarkable work being undertaken at IIT Madras, first-hand. I assure you, it will be a transforming experience.",    
    name: 'Mr Anurag Pratap',
    status : 'Vice-President, CSR | Capgemini'
    
  },
  {
    image : image3,
    review: 'Over the years, our collaboration with IIT Madras has proven to be of a tremendous value in our CSR journey. The institute has been instrumental in achieving a meaningful impact on the communities that Chola serves. I would like to extend my heartfelt gratitude to the entire IIT Madras community for their support, dedication, and commitment to making the people enter a better Life.',
    name: 'Mr Narendra Kumar N',
    status: "Senior Associate Vice President - Head CSR | CIFCL"
  },
  {
    image : image4,
    review: "Portescap India has been collaborating with IITM for CSR projects for several years now. We have collaborated in healthcare initiatives AREBO (Arm Rehabilitation Robot) and 'Sit to Stand' Mobility Projects lead by Dr. Sujatha Srinivasan and Dr. Sourav Rakshit. These unique solutions will make rehabilitation both accessible and affordable, thereby actualizing our Company's core value to drive innovative solutions to save, improve and enhance lives and CSR philosophy to work for betterment of our communities towards a more equitable society. Last year, we had the opportunity to visit the campus, meet the project teams and see the initiatives in person. To see the clarity, dedication and enthusiasm was encouraging and we are reassured that through IITM, the CSR vision of Portescap is coming to life. AN OVERWHELMING EXPERIENCE!",
    name: 'Ms Usha Vandavasi',
    status: "Senior General Manager-Finance & CFO-Portescap | India" 
  },
  {
    image : image5,
    review:'Every 4 minutes, a woman is diagnosed with breast cancer in India. We need to do everything we can to treat this and in a cost effective manner. We are delighted to associate with IIT Madras on a lifesaving project dedicated to creating an accessible and cost-effective technological solution for breast cancer patients. This collaboration is more than just an association; it represents a shared vision to bring about a change in the lives of ordinary Indians. I am truly inspired by the work being done and the teams commitment to use technology to save people’s lives.',
    name: 'Mr Rathin Lahiri',
    status: "Marketing & CSR | SBI General Insurance" 
  },
  {
    image : image6,
    review:'From the very beginning, IIT Madras demonstrated a deep understanding of the Climate Action - Research & Development projects. We are grateful for their invaluable contribution for “Development of a 250 W Compact Polymer Electrolyte Membrane (PEM) Fuel Cell Stack” for larger social good. They worked collaboratively for providing valuable insights and expertise throughout the project lifecycle. They were always available and responsive to our queries, and they addressed any challenges that arose promptly and efficiently. We are extremely grateful for the partnership with IIT Madras and look forward to working with them again in the future.',
    name: 'Mr Yogesh Kapse',
    status: "Head of CSR, Schaeffler | India" 
  },
  {
    image : image7,
    review: "Mphasis' purpose to be the 'Driver in the Driverless Car' for Global Enterprises, enables us to deliver scalable and sustainable software and technology solutions underscoring our leadership in technology-led innovation. Aligned to this purpose, our CSR arm, the Mphasis F1 Foundation, champions transformative initiatives with IIT Madras by co-establishing the Centre for Quantum Information, Communication, and Computing (CQuICC). We are delighted to be associated with IIT Madras for fundamental and applied research in quantum technologies. The Quantum Centre at IIT-M is a world-class hub of research and applied technologies in Quantum Sciences and related applied technologies producing top-quality graduates, whose research and solutions exemplifies the shared goals of innovation and societal betterment.",
    name: 'Ms Sarojini Subbiah',
    status: "VP AND Head OF CSR | Mphasis" 
  }
];
