import React from 'react'
import './CSRSubmit2022.css'
import Header from '../Component/Header';
import Footer from '../Component/Footer';

function CSRSubmit2022() {
    return (
        <>
        <Header/>
        <section className="healthhead">
        <div className="container-fluid">
            <h2 className='gallery-head' style={{color: "black"}}>CSR SUMMIT 2022</h2>
        </div>
    </section>
    <section className='container largeContainer'>
    <div className="gallery-container1">
       <div>
           <img src="assets/images/CSR/1.jpg" alt="Twelfth description"/>           
       </div>       
       <div>
           <img src="assets/images/CSR/2.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/3.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/4.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/5.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/6.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/7.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/8.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/9.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/10.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/11.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/12.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/13.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/14.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/15.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/16.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/17.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/18.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/19.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/20.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/21.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/22.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/23.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/24.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/25.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/26.jpg" alt="Twelfth description"/>           
       </div>       
       <div>
           <img src="assets/images/CSR/27.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/28.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/29.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/30.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/31.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/32.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/33.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/34.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/35.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/36.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/37.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/38.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/39.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/40.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/41.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/42.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/43.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/44.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/45.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/46.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/47.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/48.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/49.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/50.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/51.jpg" alt="Twelfth description"/>           
       </div>       
       <div>
           <img src="assets/images/CSR/52.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/53.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/54.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/55.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/56.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/57.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/58.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/59.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/60.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/61.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/62.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/63.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/64.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/65.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/66.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/67.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/68.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/69.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/70.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/71.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/72.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/73.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/74.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/75.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/76.jpg" alt="Twelfth description"/>           
       </div>       
       <div>
           <img src="assets/images/CSR/77.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/78.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/79.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/80.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/81.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/82.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/83.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/84.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/85.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/86.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/87.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/88.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/89.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/90.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/91.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/92.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/93.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/94.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/95.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/96.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/97.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/98.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/99.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/100.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/101.jpg" alt="Twelfth description"/>           
       </div>       
       <div>
           <img src="assets/images/CSR/102.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/103.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/104.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/105.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/106.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/107.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/108.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/109.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/110.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/111.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/112.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/113.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/114.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/115.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/116.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/117.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/118.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/119.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/120.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/121.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/122.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/123.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/124.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/125.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/126.jpg" alt="Twelfth description"/>           
       </div>       
       <div>
           <img src="assets/images/CSR/127.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/128.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/129.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/130.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/131.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/132.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/133.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/134.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/135.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/136.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/137.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/138.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/139.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/140.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/141.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/142.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/143.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/144.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/145.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/146.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/147.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/148.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/149.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/150.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/151.jpg" alt="Twelfth description"/>           
       </div>       
       <div>
           <img src="assets/images/CSR/152.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/153.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/154.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/155.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/156.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/157.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/158.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/159.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/160.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/161.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/162.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/163.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/164.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/165.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/166.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/167.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/168.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/169.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/170.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/171.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/172.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/173.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/174.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/175.jpg" alt="Twelfth description"/>           
       </div>
       <div>
           <img src="assets/images/CSR/176.jpg" alt="Twelfth description"/>           
       </div>
   </div>
    </section>
    

   <Footer/>
        </>
    )
}

export default CSRSubmit2022;


