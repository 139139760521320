import React from "react";
function SuccessStoriesContent() {
    return (
        <>
            <section className="page_banner" style={{ backgroundImage: "url(assets/images/bg/8.jpg)" }}>
                {/* <div className="overlay"></div>
                <div className="container largeContainer">
                    <div className="row">
                        <div className="col-md-12 text-center">
                            <h2 className="banner-title">Our Success Stories</h2>
                        </div>
                    </div>
                </div> */}
            </section>

            <section class="servicePage02 sp07">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="service_item_09">

                                <h3><a href="#0">Shri. Kris Gopalakrishnan</a></h3>
                                <p>It is very heartening to see IIT Madras sharply focused on developing technologies which will transform our society in a very positive way. Industry-academia collaboration is going to be increasingly critical in addressing the social problems in a fast-changing world.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">CO-FOUNDER, INFOSYS | ALUMNUS OF IIT MADRAS<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Mr Pratyush Panda</a></h3>
                                <p>IIT Madras is undertaking remarkable work in the areas of research, technology-driven environmental, social impact and incubation. This is in complete alignment with our honorable PM's 'Make in India' campaign, presenting excellent CSR synergies for progressive corporates looking to create lasting change.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Head Sustainability and CSR, LTI <i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09">

                                <h3><a href="#0">Mr Anurag Pratap</a></h3>
                                <p>I do not believe in creating 'islands' of change. An intervention should be able to impact society at scale, for it to have lasting impact. One of the main ways this can happen is through Technology. I commend IIT Madras for organizing this wonderful initiative, creating awareness amongst Corporate stakeholders, on the various CSR collaboration opportunities across important themes. I urge everyone here to make a visit to IIT Madras, to interact with the technology experts and witness the remarkable work being undertaken at IIT Madras, first-hand. I assure you, it will be a transforming experience.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Vice-President, CSR, Capgemini<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Shri T. T. Jagannathan</a></h3>
                                <p>The only hope for eliminating poverty in India is through technology. It has to be that kind of technology that benefits the people on the ground. I am happy to say that the research already funded by us through R2D2 is bringing real benefits to people with disabilities. I wish to congratulate IITM for this initiative.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Managing Director, TTK Group of Companies | Alumnus of IIT Madras<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09">

                                <h3><a href="#0">Shri. Vellayan Subbiah</a></h3>
                                <p>India, on its journey towards development and sustainability
                                    needs to look at planning with not just traditional solutions to
                                    conventional challenges but with an alternative strategy
                                    targeting the overarching ecosystem across the country.
                                    The Integrated Urban Governance in Metropolitan Chennai
                                    project by IITM is a step in this direction that has been
                                    commissioned with a vision of urban development and
                                    an overall trajectory of growth and sustainability.
                                    The Murugappa Group with its rich history across several
                                    industries and geographies has partnered with IITM on
                                    multiple projects over the years. We are happy to be a
                                    part of this inclusive growth story of the nation.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Chairman and Director,
                                            Cholamandalam Investment & Finance Co. Ltd.<i
                                                class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Saurabh Rastogi</a></h3>
                                <p>One of the key initiatives of REC in facilitating
                                    energy transition in the country is financing
                                    renewable energy projects as a thrust area.
                                    CSR interventions like the 2 MWp solar rooftop
                                    project in IIT Madras are aimed at a
                                    "Greener Campus" and reduction of carbon
                                    footprints of the campus, besides creating
                                    awareness about the same amongst students.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Chief General Manager (CSR),
                                            Rural Electrification Corporation Limited<i
                                                class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Indian Oil Corporation Limited (IOCL)</a></h3>
                                <p>IndianOil has been associated with IITM for many Corporate Social Responsibility programmes being implemented at different locations in the field of education, sanitation, water management and empowering the downtrodden people near to its installations. The Integrated Pottery Development project implemented at Tiruvallur in Tamil Nadu is one such programme, which has helped a large number of rural potters in enabling them to use mechanical modes of pottery making.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Indian Oil Corporation Limited (IOCL)<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Ms. Ziaa Lalkaka</a></h3>
                                <p>We wanted to support a landmark institution with the technical capabilities and innovation to think about and build critical water technologies, through a Centre of Excellence. We partnered with IITM to support Professor T. Pradeep's vision to establish the International Centre for Clean Water, a state-of-the-art research facility and entrepreneurship development centre that would not just develop affordable water technologies but also take them to market. We believe ICCW is poised to be a key player in developing, incubating and disseminating solutions for the current challenges of safe water for the country.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">CEO, HT Parekh Foundation<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Ms. Apoorva Manichandar</a></h3>
                                <p>We have partnered with ICCW for a research study focused on developing a stand-alone solar powered membrane distillation unit for potable water production. It can help provide drinking water to communities living in areas with high TDS levels in groundwater, thereby making them self-reliant.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">CSR Country Lead, Grundfos India<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Mr. Vikrant Gandhe</a></h3>
                                <p>Innovation and employability skill enhancement have always been the key focus areas of Tata Technologies. Ready Engineer offers a training ecosystem for engineering faculty and students on industry practices along with training in soft skills, employability assessments, scholarships, industry connect and innovation to enhance the employability skills of engineers in Tier II and Tier III cities. Ready Engineer was initiated in 2010 and has been implemented successfully in more than 32 engineering colleges across India (Maharashtra and Karnataka), six clusters, benefiting 100 professors and 4,600 engineers every year. Ready Engineer has also won the National CSR Award by the Ministry of Corporate Affairs, 2019. Tata Technologies feels privileged to associate with NPTEL under IITM since 2018 and looks forward to a long-term relationship enhancing the skill and knowledge ecosystem.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Company Secretary & Head - CSR, Tata Technologies Ltd.<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Mr. Arijit Chatterjee</a></h3>
                                <p>We are very happy to be associated with IITM in this environment-friendly project. Once completed, I am sure that the impact will be big.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">VP-Corporate Marketing & CSR, Tidewater Oil (India) Limited<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Ms. Bandana Jha</a></h3>
                                <p>Wells Fargo has a deep and long-standing commitment to supporting local communities. We are glad to partner with Indian Institute of Technology Madras to support their incubated start-up, Modulus Housing's 'Medicab,' which has helped communities by strengthening the healthcare infrastructure in Bihar. The 100-bed modular unit has equipped the Homi Bhabha Cancer Hospital to provide treatment to cancer patients, as well as Covid-19 patients during the pandemic.
                                    A sustainable way for corporates to support communities during the pandemic is to work with innovative start-ups, who offer scalable solutions to address problems on the ground in real time. Commitment, finding the right non-profit partners to guide the way, and focusing on solutions that will serve the community dynamically and consistently are the need of the hour for businesses.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Social Impact & Sustainability Head, Wells Fargo International Solutions Private Limited<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Ms. Usha Vandavasi</a></h3>
                                <p>Portescap India and IITM have partnered for CSR initiatives in the healthcare field - AREBO (Arm Rehabilitation Robot) and 'Sit to Stand' Mobility Projects.
                                    We are impressed with the progress in both. As a company, our core values are to deliver exceptional value to communities and society and drive innovative solutions to save, improve and enhance lives. Our partnership with IITM mirrors our core values and we look forward to further such initiatives, which bring our Corporate and CSR philosophy to life.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Director (Finance) and CFO, Portescap India Private Limited<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Mr. Sundararajan Narayanan</a></h3>
                                <p>Virtusa has been a proud sponsor of Carbon Zero Challenge, a one-of-its-kind initiative pioneered by Indian Institute of Technology Madras and Industrial Waste Management Association (IWMA). As an all-India competition, it was unique as it identified and nurtured young innovators and entrepreneurs to develop solutions that will help us move to a greener and cleaner world. The shortlisted teams received training and mentoring in addition to financial support over six months to help build their prototypes and evolve their business models. The innovative concept of this competition resonated with Virtusa's philosophy as we use our technology expertise to help reduce the environmental footprint, demonstrate ethical maturity, and respect for all.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Chief People Officer & Executive Vice President, Virtusa<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Mr. Vaggu Raghavendra</a></h3>
                                <p>With one of the richest marine ecosystems in the world, India understands the vast potential presented by marine biotechnology and has been exploring ways to utilise it. IITM-BI Marine Resource Research Facility is part of this master-plan, and has already embedded itself in this environment. Cytiva is proud and honoured to be a key partner of CIB-MRRF. Cytiva funded an R&D intervention that will focus on various means to harness marine resources for bio-manufacturing of industrially relevant products, which is in line with Cytiva's aim to promote research in India. We hope this collaboration is a huge success.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Goud General Manager - Cytiva, India & South Asia<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Mr. K. Shankaran</a></h3>
                                <p>TTK Prestige is proud to be associated with the R2D2 project of IITM that enables mobility, thus significantly improving the quality of life of those whose mobility is affected.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Director- Corporate Affairs, TTK Prestige<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="service_item_09 ml13">

                                <h3><a href="#0">Mr. B. Thiagarajan</a></h3>
                                <p>Our association with IITM started as part of the industry-academia collaboration hosted by the Ministry of Education, formerly the Ministry of Human Resource Development, around six years ago. Since then, we have engaged with IIT-Madras in areas connected with water and energy, in the HVAC&R domain. The engagements were stimulating with high cross learnings for both sides, leading to, Blue Star further entering into a partnership with them in FY22, for a value engineering project initiated by the Company for some of its critical components. Blue Star's deep domain knowledge combined with IIT's technical expertise will definitely help us achieve our milestone. As a responsible corporate citizen, Blue Star strongly believes in leveraging industry- academia collaborations to unleash innovation-driven outcomes, thereby contributing towards the research efforts undertaken by some of the best institutions.</p>
                                <a class="berpo_btn with_icon04" href="#0"><span class="bp-shape"></span><span
                                    class="bp-shape"></span><span class="bp-shape"></span><span
                                        class="bp-shape"></span><span class="bp-text">Managing Director, Blue Star Limited<i
                                            class="twi-angle-right1"></i></span></a>
                            </div>
                        </div>


                    </div>
                </div>
            </section>

        </>
    )
}
export default SuccessStoriesContent;