import React from "react";
import Footer from "../Component/Footer";
import Header from "../Component/Header";
import Preloader from "../Component/Preloader";
import PosterContent from "../Content/PosterContent";
import { useParams } from 'react-router-dom';

function Poster(){
    const { id } = useParams();
    return(
        <>
        <Preloader/>
        <Header/>
        <PosterContent postId={id} />
        <Footer/>
        </>
    )
}
export default Poster;