import React from "react";

function PartnersContent() {
    return (
        <>
            <section class="partnerh">
                <div class="container-fluid">
                    <h2 class="title01">Our Partners</h2> 
                </div>
            </section>

            <section class="teamPage">
                <div class="container largeContainer">
                    <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/1.png" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ABB INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/2.png" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ABI SHOWATECH INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/4.png" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">Acsys Investments Pvt. Ltd.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="assets/images/partners/3.png" alt=""/>
                                </div>
                                 <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">AKAMAI TECHNOLOGIES INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                      </div>
                      <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/alation-.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ALATION INDIAN PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/altair.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ALTAIR ENGINEERING INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/amadasoft-1.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">AMADASOFT INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/amadeus.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">AMADEUS SOFTWARE LABS INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                        <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/American-Express.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">AMERICAN EXPRESS INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/anjan-druga.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ANJAN DRUG PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/Ansys.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ANSYS SOFTWARE PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/arcelor-mittal.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">ARCELORMITTAL NIPPON STEEL INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div> 
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/astra.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ASTRAZENECA INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/atos.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ATOS GLOBAL IT SOLUTIONS AND SERVICES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/banca.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">BANCA SELLA S.P.A.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/blue-star.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">BLUE STAR LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>  	
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/bny-mellon.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">BNY MELLON TECHNOLOGY PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/bosch.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">BOSCH CHASSIS SYSTEMS INDIA PVT. LTD</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/capgemini.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">CAPGEMINI TECHNOLOGY SERVICES INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/Cargill.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">CARGILL INC.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>  
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/CGI.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">CGI INFORMATION SYSTEMS & MANAGEMENT CONSULTANTS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/CPCL.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">CHENNAI PETROLEUM CORPORATION LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/unnamed.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">CHOLAMANDALAM INVESTMENT AND FINANCE COMPANY LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/chola-MS.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">CHOLAMANDALAM MS GENERAL INSURANCE CO. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div> 
 	                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/CUB.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">CITY UNION BANK LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/09/cognizant.png" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">COGNIZANT</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/CAMS.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">COMPUTER AGE MANAGEMENT SERVICES </a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/macdermid-chevron-cookson.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">COOKSON INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>  
	                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/crotus.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">CROTUS TECHNOLOGIES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/03/cryptorelief-logo-edited.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">CRYPTORELIEF</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/cs.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">Cochin Shipyard LIMITED</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/cytiva-1.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">CYTIVA INDIA</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>  
	                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/dun-and-bradstreet.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">DUN & BRADSTREET TECHNOLOGIES & DATA SERVICES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/ecare.png" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">E-CARE INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/Easun-.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">EASUN - MR TAP CHANGERS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/emerson.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">EMERSON</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>  
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/fluor.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">FLUOR DANIEL INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/fullerton.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">FULLERTON INDIA CREDIT COMPANY LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/gail.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">GAS AUTHORITY OF INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/GIC-Re.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">GENERAL INSURANCE CORPORATION OF INDIA</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div> 
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/goldman-sachs.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">GOLDMAN SACHS GROUP, INC.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/hawe.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">HAWE HYDRAULICS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/hdfc.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">HDFC BANK LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/hermes.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">HERMES I TICKETS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/HTParekh-1.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">HT PAREKH FOUNDATION</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/icici-sec-1.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ICICI SECURITIES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/idg-ven.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">IDG VENTURES</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/indegene.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">INDEGENE PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>  
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/billdesk.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">INDIA IDEAS COM LTD. BILL DESK</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/india-nippon.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">INDIA NIPPON ELECTRICALS LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/indian-oil.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">INDIAN OIL CORPORATION LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/indomim.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">INDOMIM</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/inductotherm.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">INDUCTOTHERM INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/indus-biotech.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">INDUS BIOTECH PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/infineon.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">INFINEON TECHNOLOGIES INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/infosys.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">INFOSYS LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/ionixx.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">IONIXX TECHNOLOGIES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/jmitra.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">J. MITRA & CO. PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/jayshree.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">JAYASHREE POLYMERS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/JK-Fenner.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">JK FENNER INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/john-crane.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">JOHN CRANE SEALING SYSTEMS INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/Karkinos.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">KARKINOS HEALTHCARE PVT. LTD</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/kumaran.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">KUMARAN SYSTEMS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/kwality-milk.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">KWALITY MILK FOODS LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/l-and-t-infotech.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">L&T INFOTECH</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/l-and-t-tech.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">L&T TECHNOLOGY SERVICES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/lt-thales.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">L&T THALES TECHNOLOGY SERVICES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/latentview.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">LATENTVIEW ANALYTICS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/novateur-legrand.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">LEGRAND</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/lkq.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">LKQ INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/eaton.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">M/S. EATON TECHNOLOGIES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/mahindra.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">MAHINDRA & MAHINDRA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>				
                      <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/ace-micromatic-grinding.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">MICROMATIC GRINDING TECHNOLOGIES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/microsoft.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">MICROSOFT CORPORATION INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/MIL-industries.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">MIL INDUSTRIES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/mitra.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">MITRA INDUSTRIES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/mpm.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">MPM PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/MSC-Software.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">MSC SOFTWARE CORPORATION INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/nagarro-logo.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">NAGARRO</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/natesans.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">NATESAN SYNCHROCONES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/nile-limited.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">NILE LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/09/nokia.png" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">NOKIA SOLUTIONS & NETWORKS INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/NSE.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">NSE FOUNDATION</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/portescap.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">PORTESCAP INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/Power-Grid.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">POWERGRID</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/qualcomm.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">QUALCOMM</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/rk-indus.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">R.K.INDUSTRIES</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/Renault-Nissan-Technology-Business-Centre.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">RENAULT-NISSAN TECHNOLOGY AND BUSINESS CENTRE INDIA PRIVATE LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/RMKV.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">RMKV SILKS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/bosch-1.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ROBERT BOSCH ENGINEERING AND BUSINESS SOLUTIONS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/REC.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">RURAL ELECTRIFICATION CORPORATION LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/saipem.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">SAIPEM INDIA PROJECTS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/samsung.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SAMSUNG INDIA ELECTRONICS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sandvik.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SANDVIK MATERIALS TECHNOLOGY INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sar.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SAR GROUP</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sasken.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">SASKEN COMMUNICATION TECHNOLOGIES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/SBI-FOUNDATION-.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SBI FOUNDATION</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/simpl.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SECURITIES INVESTMENT MANAGEMENT PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/shakti.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SHAKTHI SUSTAINABLE ENERGY FOUNDATION</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sharp.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">SHARP BUSINESS SYSTEMS INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sldwal.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SIDWAL REFRIGERATION IDUSTRIES PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sirius.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SIRIUS COMPUTER SOLUTIONS</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/solara.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SOLARA ACTIVE PHARMA SCIENCES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sony.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">SONY PICTURES NETWORKS INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sterlite-tech.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">STERLITE TECHNOLOGIES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sundaram-bnp-paribas.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SUNDARAM BNP PARIBAS HOME FINANCE LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/super-auto-forge.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SUPER AUTO FORGE PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/sutherland-.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">SUTHERLAND GLOBAL SERVICES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/syngene.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SYNGENE INTERNATIONAL LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/syrma.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">SYRMA SGS TECHNOLOGY LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/TNPL.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TAMIL NADU NEWSPRINT AND PAPERS LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/Tata-advanced-materials-1.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">TATA ADVANCED MATERIALS LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/tata-boeing.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TATA BOEING AEROSPACE LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/TCS.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TATA CONSULTANCY SERVICES</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/tcs.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TATA CONSULTANCY SERVICES</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/tata-elxsi.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">TATA ELXSI LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/tata-lockheed-martin.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TATA LOCKHEED MARTIN AEROSTRUCTURES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/tata-tech.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TATA TECHNOLOGIES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/tek-travels.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TEK TRAVELS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/TEXAS-INSTRUMENTS.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">TEXAS INSTRUMENTS INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/thales.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">THALES</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/tide-water-oil.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TIDE WATER OIL COMPANY INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/Tiger-Analytics.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TIGER ANALYTICS</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/titan.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">TITAN COMPANY LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/TMI.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TMI GROUP</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/Trimble.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TRIMBLE INFORMATION TECHNOLOGIES INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/ttk-prestige.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TTK PRESTIGE LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/tube.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">TUBE INVESTMENTS OF INDIA LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/turbo.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">TURBO ENERGY PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/v-guard.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">V-GUARD INDUSTRIES LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/verizon.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">VERIZON DATA SERVICES INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/virtusa-polaris.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">VIRTUSAPOLARIS</a></h3>
                                </div>
                            </div>
                        </div>
                       
                    </div>
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2023/03/Walmart.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">WALMART INC.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/wellsfargo.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">WELLS FARGO EGS INDIA PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/western-digital-.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">WESTERN DIGITAL TECHNOLOGIES, INC.</a></h3>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/wood.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px"}}><a href="/">WOOD INDIA ENGINEERING & PROJECTS PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>                       
                    </div> 
                       <div class="row">
                        <div class="col-lg-3 col-md-6">
                            <div class="team_04 text-center">
                                <div class="tmThumb">
                                    <img src="https://acr.iitm.ac.in/wp-content/uploads/2022/02/zoho.jpg" alt=""/>
                                </div>
                                <div class="tmcontent">
                                    <h3 style={{fontSize:"17px",textTransform: 'uppercase'}}><a href="/">ZOHO CORPORATION PVT. LTD.</a></h3>
                                </div>
                            </div>
                        </div>

                    </div>   					
				</div>
            </section>

        </>
    )
}

export default PartnersContent;